import { useState } from "react";
import { useSelector } from "react-redux";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as Sentry from "@sentry/browser";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/shadcn/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/shadcn/ui/form";
import { Textarea } from "@/components/shadcn/ui/textarea";

const reportIssueSchema = z.object({
  // name: z.string(),
  // email: z.string().email(),
  // subject: z.string(),
  message: z
    .string()
    .min(3, { message: "Por favor ingresa una descripción del problema" }),
});

const IssueReportSection = () => {
  const [formStatus, setFormStatus] = useState(null);
  // STORE
  const user = useSelector((state) => state.user.user);
  const urls = useSelector((state) => state.urls);
  const form = useForm({
    resolver: zodResolver(reportIssueSchema),
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${urls.API_URL}/send_ticket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: `${user.name} ${user.lastname}`,
          email: user.email,
          subject: "Reporte de problema",
          message: data.message,
        }),
      });

      if (response.status === 200) {
        await response.json();
        setFormStatus("OK");
        clearForm();
      } else {
        throw new Error("Error en envío de reporte de problema.");
      }
    } catch (error) {
      setFormStatus("ERROR");
      Sentry.captureException(error);
    }
  };

  const clearForm = () => {
    form.reset();
    setFormStatus(null);
  };

  return (
    <Card className="bg-blue-50 dark:bg-slate-900 border">
      <CardHeader>
        <CardTitle className="text-lg text-blue-500 font-bold">
          ¿No encuentras lo que buscas?
        </CardTitle>
        <CardDescription className="text-base text-blue-500">
          Ayúdanos a mejorar reportándolo. Describe tu inquietud y nuestro equipo la revisará lo antes posible.
        </CardDescription>
      </CardHeader>
      <CardContent>
        {formStatus == "OK" ? (
          <div className="md:max-w-xl mx-auto my-4 p-4 bg-blue-50 border border-blue-500 rounded-lg text-sm text-center space-y-3">
            <div>
              <p>
                <strong>Recibimos tu mensaje</strong>
              </p>
              <p>Nos pondremos en contacto contigo a la brevedad.</p>
            </div>
          </div>
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              {formStatus === "ERROR" && (
                <div className="p-4 bg-blue-50 border border-blue-500 rounded-lg text-sm">
                  <p>
                    <strong>Lo sentimos, no pudimos enviar el mensaje.</strong>
                  </p>
                  <p>
                    Por favor inténtelo nuevamente o utilice otras opciones de
                    contacto.
                  </p>
                </div>
              )}

              <FormField
                control={form.control}
                name="message"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-blue-500">
                      Escribe tu consulta
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        className="resize-none h-48 rounded-lg"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="space-y-2">
                <button
                  type="submit"
                  className="py-3 px-9 w-full text-white font-semibold border border-blue-500 rounded-xl shadow-4xl focus:ring focus:ring-blue-300 bg-blue-500 hover:bg-blue-700 transition ease-in-out duration-200"
                >
                  Enviar mensaje
                </button>
                <button
                  type="button"
                  className="w-full text-muted-foreground"
                  onClick={clearForm}
                >
                  Limpiar formulario
                </button>
              </div>
            </form>
          </Form>
        )}
      </CardContent>
    </Card>
  );
};
export default IssueReportSection;
