export const FAQ = [
  {
    id: "catalogo-de-fondos",
    title: "Catálogo de fondos",
    questions: [
      {
        id: "faq12340",
        q: "¿Qué serie se muestra en el catálogo de fondos?",
        a: "El catálogo muestra la serie de menor costo* (*excluye las series  que reciben aportes de la propia administradora)",
      },
      {
        id: "faq12341",
        q: "¿Dónde encuentro la información del fondo?",
        a: "Al pinchar sobre un fondo se despliega un panel con todos los detalles del fondo tal como la información general del fondo, composición, rentabilidad y costos.",
      },
      {
        id: "faq12342",
        q: "¿Cómo puedo encontrar fondos según algún criterio ?",
        a: "Existen dos formas principales de encontrar fondos para tu selección:\n1. Utilizando los filtros del catálogo\nLos filtros están ubicados sobre el catálogo y te permiten crear grupos de fondos por tipo, categoría, gestor, etc.\nEn los selectores de filtros podrás seleccionar uno o más valores que se aplican al catálogo.\nSi necesitas volver a comenzar puedes encontrar el botón “limpiar filtros” desde el título del catálogo.\n2. Utilizando el buscador inteligente.\nSi requieres encontrar fondos por características más complejas, puedes utilizar este método y hacer combinaciones tales como “Fondos con exposición a UF”."
      },
    ],
  },
  {
    id: "crear-propuestas",
    title: "Crear propuestas",
    questions: [
      {
        id: "faq12343",
        q: "¿Cuántos fondos puedo agregar a un portafolio?",
        a: "Puedes agregar hasta 12 fondos a un portafolio.",
      },
      {
        id: "faq12344",
        q: "¿Qué tipos de propuestas puedo generar?",
        a: "Puedes generar propuestas de régimen General y APV. Cada una en un contexto separado para asegurarnos de cumplir con todos los requisitos normativos que tiene cada una de ellas. Para seleccionar el tipo de propuesta que desear crear pincha el selector de propuesta y selecciona la de APV o de régimen general. ",
      },
      {
        id: "faq12345",
        q: "¿Dónde encuentro las propuestas generadas?",
        a: "Las propuestas generadas las puedes encontrar pinchando en la sección Mis Propuestas. Puedes buscarlas por el nombre del cliente, fecha de generación, perfil del cliente u horizonte de inversión. ",
      },
      {
        id: "faq12346",
        q: "¿Puedo generar propuestas en diferentes monedas?",
        a: "No, por ahora sólo es posible generar propuestas en pesos chilenos (CLP)",
      },
      {
        id: "faq12347",
        q: "¿Cómo asignar montos/porcentajes a mi selección de fondos?",
        a: "El panel de despliegue de fondos te permite distribuir tu selección de dos maneras: Por porcentaje y por monto. Esta selección la puedes hacer en la pestaña superior del panel y puedes modificarla en cualquier etapa del proceso sin afectar tu selección.\n1. Distribución por porcentaje: Es el método por defecto, cada vez que agregas un instrumento a tu selección, el sistema calculará el porcentaje de acuerdo al número de fondos.\nPuedes modificar los valores porcentuales en cualquier momento de la selección y, si agregamos nuevos fondos, el sistema calculará automáticamente el peso en la selección.\nAdicionalmente, desde el campo Monto total que está en la parte superior, podrás ingresar un monto total al portafolio y los porcentajes de tu propuesta se distribuirán automáticamente en base a éste.\n1. Distribución por montos: Su funcionamiento es similar al de porcentaje pero en esta opción la distribución de montos se debe hacer manualmente.\n\n**Importante:** El monto total que se define en la distribución por porcentaje es independiente de las cantidades ingresadas en la distribución por montos."
      },
      {
        id: "faq12348",
        q: "¿Qué serie se le asigna a un fondo al agregarlo a la propuesta?",
        a: "Al agregar un fondo a la propuesta, se ingresa la misma serie que muestra el catálogo, que por defecto es la más barata (excluye las series de aportes propias de la administradora).\nPara cambiarla, puedes revisar las series disponibles y seleccionar la más apropiada para tu propuesta.\n"
      },
      {
        id: "faq12349",
        q: "¿Cómo creo una comparación de portafolios?",
        a: "Para comparar portafolios puedes hacerlo desde el panel de previsualización en la página del catálogo de fondos. En la parte superior encuentra el botón “+ Agregar portafolio”, y ya puedes comenzar a agregar fondos.",
      },
      {
        id: "faq12350",
        q: "¿Cómo puedo organizar los fondos de mis portafolios?",
        a: "Puedes organizar los fondos entre portafolios de dos formas:\n1. El portafolio que está activo, tiene un círculo blanco que se despliega al lado de su nombre cuando clickeas sobre él. Todos los fondos que agregues caerán a este portafolio. Si quieres cambiar de portafolio sólo debes pararte en el otro y clickear la marca para activarlo y comenzar a agregar fondos.\n1. También puedes arrastrar fondos entre portafolios si prefieres.",
      },
    ],
  },
  {
    id: "personalizacion-de-propuestas",
    title: "Personalización de propuestas",
    questions: [
      {
        id: "faq12351",
        q: "¿Cómo agrego mis colores y logo a las propuestas?",
        a: "Desde el menú de usuario, ubicado en la esquina superior derecha de la página, puedes acceder a la sección “Mi Perfil”. Allí encontrarás la sección de personalización donde podrás cambiar los colores (se aplica uno color a cada portafolio) y podrás cargar tu logo tanto para los modos light o dark del sistema.",
      },
      {
        id: "faq12352",
        q: "¿Qué secciones puedo personalizar en mi propuesta?",
        a: "Una propuesta tiene varias secciones que puedes modificar:\n1. La información del cliente, su nombre, perfil de riesgo y horizonte de inversión.\n1. Tu visión de mercado a través de los campos de “Objetivo del portafolio recomendado” y “Comentario de la recomendación”.\n1. El nombre de los portafolios. Junto al nombre del portafolio encontrarás un botón para asignar un nuevo nombre, según tu preferencia.\n1. Visibilidad de las secciones. Cuando veas un botón con un “ojo” podrás ocultar esa información y no aparecerá en la propuesta final.\n1. Información de contacto y disclamers. En la sección final podrás agregar información de tu organización, datos de contacto e información legal.\n\n**Importante:**  Una vez que has agregado esta información, el sistema la recordará para tu próxima propuesta y no tendrás que ingresarla nuevamente.",
      },
      {
        id: "faq12353",
        q: "¿Qué diferencia hay entre una propuesta web y una propuesta PDF?",
        a: "Todas tus propuestas tienen dos modalidades que te permiten entregar a tus clientes, en forma de página web y/o como un documento PDF.\n\nLa propuesta web te permite generar un link público para que tu cliente pueda visualizar la información desde cualquier dispositivo. Mientras que el documento en PDF te permite enviar un archivo liviano y optimizado para impresión que puedes mostrar o enviar a tus clientes.",
      },
      {
        id: "faq12354",
        q: "¿Qué son los Documentos de la propuesta?",
        a: "El botón “Documentos” permite descargar un archivo .zip que contiene todos los Folletos Normativos y Reglamentos Internos disponibles de los fondos que componen tus portafolios.",
      },
      {
        id: "faq12355",
        q: "¿Puedo personalizar mis propuestas en PDF?",
        a: "Si, actualmente puedes seleccionar dos tipos de personalización:\n1. Orientación: Puedes generar un PDF en formato vertical o vertical.\n1. Portada: Puedes subir una imagen de portada que aparecerá en la primera página del documento.\n\n**Importante:**  Para agregar una portada a tu propuesta PDF la imagen que utilices debe ser tamaño carta con las siguientes especificaciones de tamaño. Horizontal(816x1056px) o vertical (1056x816px).",
      },
    ],
  },
];
