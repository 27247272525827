import { useState, useMemo, useEffect, useRef, useContext } from "react"
import _ from "lodash"
// STORE
import { useSelector, useDispatch } from "react-redux"
import {
  setOverviewPanelOpen,
  // togglePreviewPanel,
} from "../../../store/portfolioPanelSlice"
// CONTEXT
import { FirebaseContext } from "../../../firebase"
// HOOKS
import useResizeObserver from "../../../hooks/useResizeObserver"

// PROPTYPES
import { appCatalogPropTypes } from "../../../propTypes/AppPropTypes"

// UI
// import GridFrame from "./GridFrame"
import DataGridHead from "./DataGridHead"
import DataGridRow from "./DataGridRow"
import DataGridPagination from "./DataGridPagination"

import { AVERAGE_CREDIT_QUALITY_MAP, SECTOR_MAP } from "../utilities/dataMaps"
import useTracking from "../../../hooks/useTracking"

function DataGrid({ catalog }) {
  // TRACK
  const trackEvent = useTracking()
  const [tableData, setTableData] = useState(null)
  // REF
  const previousDataGridHeight = useRef(0)
  const datagridRef = useRef()
  // HOOKS
  const dimensions = useResizeObserver(datagridRef)
  // STATE
  const [resetDimensions, setResetDimensions] = useState(false)
  const currency = useSelector((state) => state.proposalType.currency)
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  const { logAnalyticsEvent } = firebase
  // STORE
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const sortOptions = useSelector((state) => state.sorting.data)
  const paginationInfo = useSelector((state) => state.pagination.data)
  const customColumnKey = useSelector((state) => state.column.current)
  const columnOptions = useSelector((state) => state.column.options)
  const isOverviewPanelOpen = useSelector(
    (state) => state.portfolioPanel.isOverviewOpen,
  )

  const dataGridConfig = useMemo(
    () => [
      {
        name: "Nombre del Fondo",
        cellKey: "fund_name",
        sortable: false,
        action: "search",
        showSearch: true,
        span: "sticky left-0 pl-2 z-[9] sm:pl-4 lg:pl-8",
        display: "",
        align: "text-left",
        justify: "justify-left",
        lineBreak: null,
      },
      {
        name: "Tipo", // "Tipo de Fondo",
        cellKey: "fund_type",
        sortable: false,
        action: "typeFilter",
        showSearch: false,
        span: "",
        display: "",
        align: "text-center",
        justify: "justify-center",
        lineBreak: null,
      },
      {
        name: "Clase", // "Clase de activo",
        title: "Clase de activo",
        cellKey: "asset_class",
        sortable: false,
        action: "filter",
        showSearch: true,
        span: "",
        display: "",
        align: "text-left",
        justify: "justify-left",
        lineBreak: null,
      },
      {
        name: "Categoría",
        cellKey: "category",
        sortable: false,
        action: "catFilter",
        showSearch: true,
        span: "",
        display: "",
        align: "text-left",
        justify: "justify-left",
        lineBreak: null,
      },
      {
        name: "Gestor",
        cellKey: "fund_provider",
        sortable: false,
        action: "providerFilter",
        showSearch: true,
        span: "",
        display: "",
        align: "text-left",
        justify: "justify-left",
        lineBreak: null,
      },
      {
        name: "Moneda", // "Moneda Fondo",
        title: "Moneda del fondo",
        cellKey: "fund_currency",
        sortable: false,
        action: "filter",
        showSearch: false,
        span: "",
        display: "",
        align: "text-center",
        justify: "justify-center",
        lineBreak: null,
      },
      {
        name: "Patrimonio", // "Patrimonio Fondo",
        title: "Patrimonio del fondo",
        cellKey: "fundAum",
        sortable: true,
        span: "",
        display: "",
        action: "aumFilter",
        showSearch: true,
        align: "text-center",
        justify: "justify-center",
        lineBreak: null,
      },
      {
        name: "Costo (TAC)",
        title: "TAC Promedio del Fondo",
        cellKey: "fundTac",
        sortable: true,
        action: null,
        showSearch: true,
        span: "",
        display: "",
        align: "text-center",
        justify: "justify-center",
        lineBreak: null,
      },
      {
        name: "Rentab. 1A",
        title: "Rentabilidad promedio del fondo 1 año",
        cellKey: "fundReturn1Y",
        sortable: true,
        action: null,
        showSearch: true,
        span: "",
        display: "",
        align: "text-center",
        justify: "justify-center",
        lineBreak: null,
      },
      {
        name: "Rentab. 2A",
        title: "Rentabilidad promedio del fondo 2 años",
        cellKey: "fundReturn2Y",
        sortable: true,
        action: null,
        showSearch: true,
        span: "",
        display: "hidden 3xl:flex",
        align: "text-center",
        justify: "justify-center",
        lineBreak: null,
      },
      {
        name: "Rentab. 3A",
        title: "Rentabilidad promedio del fondo 3 años",
        cellKey: "fundReturn3Y",
        sortable: true,
        action: null,
        showSearch: true,
        span: "",
        display: "hidden 3xl:flex",
        align: "text-center",
        justify: "justify-center",
        lineBreak: null,
      },
      {
        name: "M. Mínimo",
        title: "Monto mínimo de inversión del fondo",
        cellKey: "fund_minimum_investment",
        sortable: true,
        action: null,
        showSearch: true,
        span: "",
        display: "",
        align: "text-center",
        justify: "justify-center",
        lineBreak: null,
      },
      {
        name: columnOptions[customColumnKey].label,
        cellKey: customColumnKey,
        sortable: true,
        action: "custom",
        showSearch: true,
        span: "",
        display: "",
        align:
          columnOptions[customColumnKey].type === "text"
            ? "text-left"
            : "text-center",
        justify:
          columnOptions[customColumnKey].type === "text"
            ? "justify-start"
            : "justify-center",
        lineBreak: 1,
      },
    ],
    [columnOptions, customColumnKey, currency],
  )

  // CALLBACKS
  const handleRowToggle = (e, rowKey) => {
    if (e.target.tagName === "DIV") {
      trackEvent({
        action: "TABLE_FUND_SCREENER",
        timestamp: Date.now(),
        type: "CLICK",
        data: rowKey,
      })
      logAnalyticsEvent("select_content", {
        content_type: "Panel de detalles de un fondo",
        content_id: rowKey,
        username: `${user.name} ${user.lastname}`,
        company: user.company,
      })
      const newValue = rowKey === isOverviewPanelOpen ? null : rowKey
      // dispatch(togglePreviewPanel(false)) // Close preview panel
      dispatch(setOverviewPanelOpen(newValue))
      setResetDimensions(!resetDimensions)
    }
  }

  // LIFECYCLE
  useEffect(() => {
    if (catalog) {
      // ORDER LIST BY SORT OBJECT
      const sortedData = _.sortBy(catalog, (o) => {
        let currencySuffix = "Clp"
        if (currency === "USD") {
          currencySuffix = "Usd"
        }
        if (sortOptions.sortBy !== null && sortOptions.sortBy.startsWith("fundReturn")) {
          const fundReturnColumn = `${sortOptions.sortBy}${currencySuffix}`
          return o[fundReturnColumn] !== null ? o[fundReturnColumn] : 0
        }
        if (sortOptions.sortBy !== null && sortOptions.sortBy.startsWith("fundAum")) {
          const fundAumColumn = `${sortOptions.sortBy}${currencySuffix}`
          return o[fundAumColumn] !== null ? o[fundAumColumn] : 0
        }
        if (
          sortOptions.sortBy === "avg_credit_quality" &&
          o[sortOptions.sortBy] !== null
        ) {
          return Object.prototype.hasOwnProperty.call(
            AVERAGE_CREDIT_QUALITY_MAP,
            o[sortOptions.sortBy],
          )
            ? AVERAGE_CREDIT_QUALITY_MAP[o[sortOptions.sortBy]]
            : 3
        }
        if (
          sortOptions.sortBy === "stock_sector" &&
          o[sortOptions.sortBy] !== null &&
          o[sortOptions.sortBy] !== ""
        ) {
          return Object.prototype.hasOwnProperty.call(
            SECTOR_MAP,
            o[sortOptions.sortBy],
          )
            ? SECTOR_MAP[o[sortOptions.sortBy]]
            : 1
        }
        if (typeof o[sortOptions.sortBy] === "string") {
          return o[sortOptions.sortBy].toUpperCase()
        }
        if (o[sortOptions.sortBy] === null || o[sortOptions.sortBy] === "") {
          // force null to beginning
          return -1
        }
        return o[sortOptions.sortBy]
      })

      if (sortOptions.sortOrder === "DESC") sortedData.reverse()

      // SAVE TOTAL PAGES
      const sliceStart =
        (paginationInfo.currentPage - 1) * paginationInfo.resultsPerPage
      const sliceEnd = sliceStart + paginationInfo.resultsPerPage

      // SET DATA FOR TABLE
      setTableData(sortedData.slice(sliceStart, sliceEnd))
    }
  }, [catalog, sortOptions, paginationInfo, currency])

  useEffect(() => {
    if (dimensions) {
      const dataGridHeight = dimensions.height
      previousDataGridHeight.current = dataGridHeight
      setResetDimensions(!resetDimensions)
      // if (dataGridHeight !== previousDataGridHeight.current) {
      //   console.log(catalog)
      // }
    }
  }, [dimensions, catalog])

  return (
    <>
      <div className="min-w-full overflow-x-auto 1xl:overflow-x-visible">
        <div ref={datagridRef} className="min-w-[1432px] align-middle">
          <div className="min-w-full divide-y divide-slate-200 dark:divide-slate-800">
            <DataGridHead headings={dataGridConfig} />
            {tableData && tableData?.length === 0 ? (
              <div className="w-full py-10 px-8 min-h-[500px]">
                <h2 className="mb-2 text-xl">No pudimos encontrar fondos.</h2>
                <p className="text-sm">
                  Por favor, intente nuevamente con un criterio diferente
                  utilizando los filtros o la barra de búsqueda.
                </p>
              </div>
            ) : (
              <div className="divide-y divide-slate-200 dark:divide-slate-800 min-h-[500px]">
                {tableData?.map((row) => {
                  const rowKey = `fund-${row.run}-${row.serie}`
                  const isPanelOpen = isOverviewPanelOpen === rowKey
                  return (
                    <DataGridRow
                      key={rowKey}
                      rowData={row}
                      rowKey={rowKey}
                      isPanelOpen={isPanelOpen}
                      handleRowToggle={handleRowToggle}
                      dataGridConfig={dataGridConfig}
                      resetDimensions={resetDimensions}
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <DataGridPagination />
    </>
  )
}
DataGrid.propTypes = {
  catalog: appCatalogPropTypes,
}
DataGrid.defaultProps = {
  catalog: null,
}

export default DataGrid
