import { useEffect, Fragment, useState, useRef, useContext } from "react"
import PropTypes from "prop-types"
// STORE
import { useSelector } from "react-redux"
// UI
import FundDetails from "../FundDetails/FundDetails"
import DataGridRowNav from "./DataGridRowNav"
// URILS
import { parseAPIDate } from "../../../utils/DateCalculations"
// FACTORY
import ShareClassFactory from "../../../utils/ShareClassFactory"
// HOOKS
import useScroll from "../../../hooks/useScroll"
import useViewportSize from "../../../hooks/useViewportSize"
// PRESENTER
import {
  formatShareClassMaxMinProfitAndLossesToChart,
  windowsSizeMapping,
} from "../../../utils/MaxMinProfitAndLossesPresenter"
import { formatExposuresToChart } from "../../../utils/ExposurePresenter"
import formatShareClassBenefits from "../../../utils/BenefitsPresenter"
import formatFundRisks from "../../../utils/RisksPresenter"

// PROPTYPES
import {
  FundDataShape,
  dataGridConfigProptypes,
} from "../../../propTypes/AppPropTypes"

// UTILS
import {
  formatAmounts,
  formatToFloat,
  displayString,
  displayFundProvider,
  formatMinimumInvestment,
} from "../utilities/functions"
import { FirebaseContext } from "../../../firebase"

// SENTRY
import * as Sentry from "@sentry/browser";

function DataGridRow({
  rowData,
  rowKey,
  isPanelOpen,
  handleRowToggle,
  dataGridConfig,
  resetDimensions,
}) {
  // REF
  const rowRef = useRef(null)
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  // HOOKS
  const scrollPos = useScroll()
  const viewportSize = useViewportSize()
  // STATE
  const [rowSizeInfo, setRowSizeInfo] = useState(null)
  const [panelData, setPanelData] = useState(null)
  const customColumnKey = useSelector((state) => state.column.current)
  const columnOptions = useSelector((state) => state.column.options)
  const catalogByFunds = useSelector((state) => state.catalogByFunds.data)
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  const currency = useSelector((state) => state.proposalType.currency)

  const getColConfig = (key, isCustom = false) => {
    const cell = dataGridConfig.find((e) => e.cellKey === key)
    const display =
      !isCustom && (key === "fundReturn2Y" || key === "fundReturn3Y")
        ? cell.display
        : ""
    return `${cell.span} ${cell.align} ${display}`
  }
  const formatColumnValue = (key, value) => {
    const { suffix } = columnOptions[key]
    if (key === "fund_minimum_investment") {
      let formated = value
      if (!Number.isNaN(+value)) {
        formated = formatAmounts(+value)
      } else if (value !== "S/I") {
        const number = value.slice(3)
        formated = `USD${formatAmounts(number)}`
      }
      return formated
    }
    let formatedValue = value === null ? "-" : value
    if (columnOptions[key].type === "int") {
      if (+value === -1000) {
        return "-"
      }
      formatedValue = formatAmounts(+value)
    }
    if (columnOptions[key].type === "float") {
      if (+value === -1000 || value === null) {
        return "-"
      }
      formatedValue = formatToFloat(+value, 2, suffix)
    }
    return formatedValue
  }

  const getPerformanceInstrumentName = () => {
    switch (rowData.fund_type) {
      case "IN":
        return rowData.fund_name
      default:
        return `${rowData.fund_name} - ${rowData.serie}`
    }
  }
  useEffect(() => {
    if (isPanelOpen) {
      const fetchData = async (fundId, shareClassName, fundType) => {
        try {
          const shareClassInstance = await ShareClassFactory.getShareClass(
            fundId,
            shareClassName,
            isAPV,
            fundType,
            firebase,
            currency
          )
          const shareClassReturnsStatistics =
            shareClassInstance.getReturnsStatistics(
              Object.keys(windowsSizeMapping).map((key) => parseInt(key, 10)),
            )
          const maxMinProfitLossesChartData =
            formatShareClassMaxMinProfitAndLossesToChart(
              shareClassReturnsStatistics.maxMinProfitAndLosses,
              shareClassInstance.getShareClassId(),
            )
          const allocations = formatExposuresToChart(
            rowData.asset_class,
            shareClassInstance.exposures,
          )
          const fundRisks = formatFundRisks(shareClassInstance.fundGeneralInfo)
          const shareClassBenefits = formatShareClassBenefits(
            shareClassInstance.shareClassGeneralInfo,
          )
          setPanelData({
            status: "OK",
            overview: {
              fund: {
                fundId: rowData.run,
                fundType: rowData.fund_type,
                fundInceptionDate: rowData.fund_inception_date,
                currency: rowData.fund_currency,
                category: rowData.category,
                asset_class: rowData.asset_class,
                objective: shareClassInstance.fundGeneralInfo.objective,
                isRedeemable: shareClassInstance.fundGeneralInfo.isRedeemable,
                isRenewable:
                  shareClassInstance.shareClassGeneralInfo.isRenewable,
                aum: shareClassInstance.fundGeneralInfo.aum.netFundPatrimony,
                aumDate: shareClassInstance.fundGeneralInfo.aum.closingDate,
                prospectusCode:
                  shareClassInstance.fundGeneralInfo.prospectusCode,
                risks: fundRisks.join(", "),
                minimumInvestmentClp:
                  shareClassInstance.shareClassGeneralInfo.minimumInvestmentClp,
                minimumInvestment:
                  shareClassInstance.shareClassGeneralInfo.minimumInvestment,
                entryRequirements: rowData.entry_requirements,
              },
              shareClass: {
                shareClassName: rowData.serie,
                tac: rowData.tac,
                tacDate: rowData.tacDate,
                shareClassInceptionDate: rowData.share_class_inception_date,
                aum: shareClassInstance.shareClassGeneralInfo.aum,
                returnsDate: rowData.returns_date,
                benefits: shareClassBenefits.join(", "),
                maxRedemptionPaymentTime:
                  shareClassInstance.shareClassGeneralInfo
                    .maxRedemptionPaymentTime,
                instance: shareClassInstance,
                ret: shareClassInstance.getReturnsDF().copy(),
              },
              manager: {
                managerName: rowData.fund_provider,
                managerId: rowData.fund_provider_id,
              },
            },
            performance: {
              "-1": [
                {
                  data: shareClassReturnsStatistics.formattedAccReturns,
                  name: getPerformanceInstrumentName(),
                },
              ],
            },
            returnsTable: {
              data: catalogByFunds[rowData.run],
            },
            risks: {
              totalPortfolios: 1,
              analysisData: maxMinProfitLossesChartData,
            },
            allocations: {
              allocations_date: parseAPIDate(
                shareClassInstance.exposures.closing_date,
              ),
              values: allocations,
            },
          })
        } catch (e) {
          console.log(e)
          Sentry.captureException(e);
          setPanelData({
            status: "ERROR",
          })
        }
      }
      fetchData(rowData.run, rowData.serie, rowData.fund_type)
    }
  }, [isPanelOpen, rowData, rowKey, currency])

  useEffect(() => {
    if (rowRef.current) {
      const { top, height, width } = rowRef.current.getBoundingClientRect()
      setRowSizeInfo({
        top,
        height,
        width,
      })
    }
  }, [scrollPos, resetDimensions, viewportSize])

  const getHeading = () => {
    switch (rowData.fund_type) {
      case "IN":
        return null
      default:
        return <div className="text-xxs">{rowData.run}</div>
    }
  }

  const getColumnReturnFormattedValue = (columnKey) => {
    const returnsKeys = ["fundReturn1Y", "fundReturn2Y", "fundReturn3Y", "fundReturn1M", "fundReturn3M", "fundReturn6M", "fundReturnYTD"]
    let modifiedKey = columnKey
    if (returnsKeys.includes(columnKey)) {
      if (currency === "CLP") {
        modifiedKey = `${columnKey}Clp`
      } else {
        modifiedKey = `${columnKey}Usd`
      }
    }
    return rowData[modifiedKey] === null
      ? "-"
      : formatToFloat(+rowData[modifiedKey])
  }

  const getColumnReturnValue = (columnKey) => {
    const returnsKeys = ["fundReturn1Y", "fundReturn2Y", "fundReturn3Y", "fundReturn1M", "fundReturn3M", "fundReturn6M", "fundReturnYTD"]
    let modifiedKey = columnKey
    if (returnsKeys.includes(columnKey)) {
      if (currency === "CLP") {
        modifiedKey = `${columnKey}Clp`
      } else {
        modifiedKey = `${columnKey}Usd`
      }
    }
    return rowData[modifiedKey];
  }


  const getAUMValue = () => {
    switch (rowData.fund_type) {
      case "IN":
        return "-"
      default:
        if (currency === "CLP") {
          return rowData.fundAumClp === null ? "-" : formatAmounts(+rowData.fundAumClp)
        } else {
          return rowData.fundAumUsd === null ? "-" : formatAmounts(+rowData.fundAumUsd)
        }
    }
  }

  return (
    <Fragment key={rowKey}>
      <div
        ref={rowRef}
        onClick={(e) => handleRowToggle(e, rowKey)}
        role="button"
        tabIndex="0"
        onKeyUp={(e) => handleRowToggle(e, rowKey)}
        className="group relative pr-2 grid items-center font-light text-xs
          grid-cols-dataGrid lg:grid-cols-lgDataGrid 1xl:grid-cols-xl1DataGrid 2xl:grid-cols-xl2DataGrid 3xl:grid-cols-xl3DataGrid 4xl:grid-cols-xl4DataGrid
          sm:pr-2 lg:pr-8 2xl:text-sm
          bg-white dark:bg-slate-900 hover:bg-slate-100 dark:hover:bg-slate-700
          hover:text-blue-500 dark:hover:text-blue-400
          data-[open=true]:text-blue-500 data-[open=true]:bg-slate-100
          dark:data-[open=true]:text-blue-400 dark:data-[open=true]:bg-slate-700
        "
        data-open={!!isPanelOpen}
      >
        <div
          className={`pr-3 py-3
           ${isPanelOpen
              ? "bg-slate-100 dark:bg-slate-700"
              : "bg-white dark:bg-slate-900 "
            }
            ${getColConfig("fund_name")}
            group-hover:bg-slate-100 dark:group-hover:bg-slate-700
          `}
        >
          <div className="grid place-content-start">
            {getHeading()}
            <div
              className="line-clamp-2 break-words"
              title={displayString(rowData.fund_name)}
            >
              {displayString(rowData.fund_name)}
            </div>
          </div>
        </div>
        {/* <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "serie",
          )} line-clamp-1 break-words pr-2`}
        >
          {rowData.serie}
        </div> */}
        <div
          className={`${getColConfig(
            "fund_type",
          )} line-clamp-1 break-words pr-2 mx-auto`}
        >
          <span
            className={`flex items-center justify-center w-6 h-6 shrink-0 rounded-full text-xxs bg-transparent border
            ${rowData.fund_type === "FM" &&
              "border-violet-500 dark:border-violet-400 text-violet-500 dark:text-violet-400"
              }
            ${rowData.fund_type === "FI" &&
              "border-cyan-500 dark:border-cyan-400 text-cyan-500 dark:text-cyan-400"
              }
            ${rowData.fund_type === "IN" &&
              "border-amber-500 dark:border-amber-400 text-amber-500 dark:text-amber-400"
              }
            ${rowData.fund_type === "FE" &&
              "border-lime-500 dark:border-lime-400 text-lime-500 dark:text-lime-400"
              }
          `}
          >
            {rowData.fund_type}
          </span>
        </div>
        <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "asset_class",
          )}  line-clamp-2 break-words pr-2`}
        >
          {rowData.asset_class}
        </div>
        <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "category",
          )}  line-clamp-2 break-words pr-2`}
          title={rowData.category}
        >
          {rowData.category}
        </div>
        <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "fund_provider",
          )}  line-clamp-2 break-words`}
          title={rowData.fund_provider}
        >
          {displayFundProvider(rowData.fund_provider)}
        </div>
        <div
          className={`${getColConfig(
            "fund_currency",
          )}  line-clamp-2 break-words`}
        >
          {rowData.fund_currency}
        </div>
        <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "fundAum",
          )}  line-clamp-2 break-words slashed-zero tabular-nums`}
        >
          {getAUMValue()}
        </div>
        <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "fundTac",
          )}  line-clamp-2 break-words slashed-zero tabular-nums`}
        >
          {rowData.fundTac === null ? "-" : formatToFloat(+rowData.fundTac)}
        </div>
        <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "fundReturn1Y",
          )}  line-clamp-2 break-words slashed-zero tabular-nums`}
        >
          {getColumnReturnFormattedValue("fundReturn1Y")}
        </div>
        <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "fundReturn2Y",
          )}  line-clamp-2 break-words slashed-zero tabular-nums 3xl:!block`}
        >
          {getColumnReturnFormattedValue("fundReturn2Y")}
        </div>
        <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "fundReturn3Y",
          )}  line-clamp-2 break-words slashed-zero tabular-nums 3xl:!block`}
        >
          {getColumnReturnFormattedValue("fundReturn3Y")}
        </div>
        <div
          className={`pr-3 2xl:pr-3 ${getColConfig(
            "fund_minimum_investment",
          )}  line-clamp-2 break-words slashed-zero tabular-nums`}
        >
          {rowData.fund_minimum_investment === null ||
            rowData.fund_minimum_investment === undefined
            ? "-"
            : formatMinimumInvestment(rowData.fund_minimum_investment)}
        </div>
        <div
          className={`pr-3 2xl:pr-3 text-xs break-words h-full grid
            ${getColConfig(customColumnKey, true)}
            ${columnOptions[customColumnKey].type === "number"
              ? "justify-start items-center slashed-zero tabular-nums"
              : "place-content-center"
            }`}
        >
          {formatColumnValue(customColumnKey, getColumnReturnValue(customColumnKey))}
        </div>
        <DataGridRowNav
          rowData={rowData}
          isPanelOpen={isPanelOpen}
          rowSizeInfo={rowSizeInfo}
        />
      </div>
      <div className="border-none">
        <FundDetails
          isPanelOpen={isPanelOpen}
          panelData={panelData}
          rowData={rowData}
        />
      </div>
    </Fragment>
  )
}

DataGridRow.propTypes = {
  rowData: PropTypes.shape(FundDataShape).isRequired,
  rowKey: PropTypes.string.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
  handleRowToggle: PropTypes.func.isRequired,
  dataGridConfig: dataGridConfigProptypes.isRequired,
  resetDimensions: PropTypes.bool.isRequired,
}

export default DataGridRow
