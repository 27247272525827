// SENTRY
import * as Sentry from "@sentry/browser";

const ASSET_CLASS_MAPPING = {
  Accionarios: ["ASSET_CLASS", "SECTOR_ICB", "COUNTRY"],
  Balanceados: ["ASSET_CLASS", "ZONE", "RISK"],
  "Fondo Mutuo Extranjero": ["ASSET_CLASS", "ZONE", "RISK"],
  "Capital Privado": ["ASSET_CLASS", "SECTOR_ICB", "COUNTRY"],
  Deuda: ["ASSET_CLASS", "RISK", "CURRENCY"],
  "Deuda Privada": ["ASSET_CLASS", "RISK", "CURRENCY"],
  Estructurados: ["ASSET_CLASS", "COUNTRY", "RISK"],
  Inmobiliarios: ["ASSET_CLASS", "COUNTRY", "CURRENCY"],
  "Inversionistas Calificados": ["ASSET_CLASS", "ZONE", "COUNTRY"],
  Sectorial: ["ASSET_CLASS", "SECTOR_ICB", "COUNTRY"],
  Otros: ["ASSET_CLASS", "COUNTRY", "ZONE"],
}

const EXPOSURE_TYPE_NAME_MAPPING = {
  ASSET_CLASS: "Tipo de Activo",
  COUNTRY: "País",
  CURRENCY: "Moneda",
  ZONE: "Zona",
  SECTOR_ICB: "Sector",
  RISK: "Clasificación de Riesgo",
}

const formatExposuresToChart = (assetClass, exposures) => {
  try {
    const allocations = {}
    const exposuresData = exposures
    if (Object.keys(exposuresData?.exposures)?.length > 0) {
      Object.keys(exposuresData.exposures).forEach((alloc) => {
        // filter exposures by asset class
        if (ASSET_CLASS_MAPPING[assetClass]?.includes(alloc)) {
          allocations[alloc] = { name: alloc, data: [] }
          Object.keys(exposuresData.exposures[alloc]).forEach((item) => {
            allocations[alloc].data.push({
              label: exposuresData.exposures[alloc][item].item,
              value: exposuresData.exposures[alloc][item].percentage,
            })
          })
        }
      })
    }
    return allocations
  } catch (e) {
    Sentry.captureException(e);
    console.error(e)
    throw e
  }
}

export { formatExposuresToChart, EXPOSURE_TYPE_NAME_MAPPING }
