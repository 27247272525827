export const AVERAGE_CREDIT_QUALITY_MAP = {
  AAA: 10,
  AA: 9,
  A: 8,
  BBB: 7,
  BB: 6,
  B: 5,
  "Below B": 4,
}

export const SECTOR_MAP = {
  "Basic Materials": 12,
  "Communication Services": 11,
  "Consumer Cyclical": 10,
  "Consumer Defensive": 9,
  Energy: 8,
  "Financial Services": 7,
  Healthcare: 6,
  Industrials: 5,
  RealEstate: 4,
  Technology: 3,
  Utilities: 2,
}

export const CURRENCIES_MAP = {
  "CLP": "Pesos",
  "USD": "Dólares",
  "EURO": "Euros",
}