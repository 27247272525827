import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isAPV: false,
  currency: "CLP",
}

export const proposalTypeSlice = createSlice({
  name: "proposalType",
  initialState,
  reducers: {
    toggleProposalType: (state, action) => {
      state.isAPV = action.payload
    },
    toggleProposalCurrency: (state, action) => {
      state.currency = action.payload
    }
  },
})

export const { toggleProposalType, toggleProposalCurrency } = proposalTypeSlice.actions

export default proposalTypeSlice.reducer
