const FundProviderDictionary = new Map([
  ["Alliancebernstein", "AllianceBernstein"],
  ["Blackrock", "BlackRock"],
  ["Bluebay", "BlueBay"],
  ["Doubleline", "DoubleLine"],
  ["La FinancièRe De L'ÉChiquier", "La Financière de l'Échiquier"],
  ["Mainfirst", "MainFirst"],
  ["Pinebridge", "PineBridge"],
  ["Vaneck", "VanEck"],
  ["Wisdomtree", "WisdomTree"],
  ["Bancoestado", "BancoEstado"],
  ["Larrainvial", "LarrainVial"],
  ["Soyfocus", "SoyFocus"],
  ["Banchile", "Banchile Inversiones"],
  ["Bancoestado", "BancoEstado"],
  ["Bice", "BICE"],
  ["Btg", "BTG"],
  ["Itau", "Itaú"],
  ["Mbi", "MBI Inversiones"],
  ["Prudential", "Prudential AGF"],
  ["Santander", "Santander Asset Management"],
  ["Sartor", "Sartor Finance Group"],
  ["Security", "Inversiones Security"],
  ["Sura", "SURA"],
  ["Bci", "BCI"],
  ["Finasset", "FinAsset"],
  ["Hmc", "HMC"],
  ["Weg", "WEG"],
  ["Xlc", "XLC"],
  ["Nvs", "Nevasa"],
  ["Nn", "NN"],
  ["Jpmorgan", "JPMorgan"],
])

export default FundProviderDictionary
