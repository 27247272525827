import { useState, useEffect, useContext } from "react"

// STORE
import { useSelector, useDispatch } from "react-redux"
import { updateReducedData } from "../../store/reducedDataSlice"
import { updateCategoriesMapping } from "../../store/categoriesMappingSlice"
import { updateRawCatalog } from "../../store/rawCatalogSlice"

// COMPONENTS & UI
import Screener from "../../components/ui/screener/Screener"
import LoadingSpinner from "../../components/ui/loadingSpinner/LoadingSpinner"
import reduceData from "../../components/helpers/Reducers"
import PreviewPanel from "../../components/ui/PreviewPanel/PreviewPanel"

// SETTINGS
import SETTINGS from "../../settings/settings"

// UTILS
import ErrorPage from "../ErrorPage/ErrorPage"
import ModalVersion from "../../components/ui/ModalVersion/ModalVersion"

// HOOKS
import useCatalogByFunds from "../../hooks/useCatalogByFunds"

// CONTEXT
import { FirebaseContext } from "../../firebase"
import { DataFetch } from "../../hooks/useFetch"
// SENTRY
import * as Sentry from "@sentry/browser";


function ScreenerPage() {
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  // STATE
  const [error, setError] = useState(false) // Estado para manejar errores
  const [dataReady, setDataReady] = useState(null)
  const [rawData, setRawData] = useState(null)

  // HOOKS
  const { setCatalogsByFunds } = useCatalogByFunds()

  // STORE
  const dispatch = useDispatch()
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  const rawCatalog = useSelector((state) => state.rawCatalog.data)

  const getData = async (url) => {
    try {
      const response = await DataFetch(`${SETTINGS.urls.API_URL}${url}`, {}, { firebase }, "GET");
      return response;
    } catch (error) {
      Sentry.captureException(error);
      throw new Error(error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Promise.all([
          getData("/funds"),
          getData("/funds_filters"),
          getData("/categories_mapping")]
        )

        const duplicatedSeries = []
        const catalog = []
        response[0].forEach((serie) => {
          const fondoSerie = `${serie.run}-${serie.serie}`
          if (!duplicatedSeries.includes(fondoSerie)) {
            if (!serie.is_apv || (serie.is_apv && serie.real_ret_6m !== null)) {
              duplicatedSeries.push(fondoSerie)
              catalog.push(serie)
            }
          }
        })

        const filters = response[1];
        const categoriesMapping = response[2];
        dispatch(updateRawCatalog(catalog));

        setRawData({
          filters,
          categoriesMapping,
        })
      } catch (catchError) {
        Sentry.captureException(catchError);
        console.error(catchError)
        setError(true)
      }
    }

    fetchData()
  }, [dispatch, isAPV])

  useEffect(() => {
    if (rawData && rawCatalog) {
      setCatalogsByFunds()
      dispatch(updateCategoriesMapping(rawData.categoriesMapping))
      const reducedData = reduceData(
        rawData.filters,
        rawData.filters,
        rawCatalog,
      )
      dispatch(updateReducedData(reducedData))
      setDataReady({ catalog: rawCatalog, filters: rawData.filters })
    }
  }, [rawData, isAPV, dispatch, rawCatalog])

  if (error) {
    return <ErrorPage />
  }

  return !dataReady ? (
    <div className="w-screen h-screen">
      <LoadingSpinner />
    </div>
  ) : (
    // <div className="h-screen overflow-x-hidden">
    <div className="h-screen">
      <ModalVersion />
      <Screener appFilters={dataReady.filters} />
      <PreviewPanel />
    </div>
  )
}

export default ScreenerPage
