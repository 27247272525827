/* eslint-disable no-unused-vars */
import { useCallback, useContext, useRef } from "react"
// STORE
import { useSelector, useDispatch } from "react-redux"
import { clone } from "lodash"
import {
  updatePortfolioSelection,
  updateActivePortfolio,
  enablePortfolioB,
  updateBaseAmount,
  updateDistributionOption,
} from "../../../store/portfolioSelectionSlice"
import { setPortfolioComposition } from "../../../store/portfolioCompositionSlice"
import { updatePortfoliosName } from "../../../store/portfoliosNameSlice"
import { DataFetch } from "@/hooks/useFetch"
import { FirebaseContext } from "@/firebase"

const usePreviewPanel = () => {
  const maxFundsPerPortfolio = useRef(12)
  const minimumAmmountPerFund = 100 // PESOS. !CUANTO ES EL MONTO MINIMO EN DOLARES ??
  // FIREBASE
  const firebase = useContext(FirebaseContext)
  // STORE
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const urls = useSelector((state) => state.urls);
  const rawCatalog = useSelector((state) => state.rawCatalog.data)
  const currency = useSelector((state) => state.proposalType.currency);
  const portfolioComposition = useSelector(
    (state) => state.portfolioComposition.funds,
  )
  const portfolioSelection = useSelector(
    (state) => state.portfolioSelection.data,
  )
  const activePortfolio = useSelector(
    (state) => state.portfolioSelection.activePortfolio,
  )
  const isPortfolioBEnabled = useSelector(
    (state) => state.portfolioSelection.portfolioBEnabled,
  )
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  )
  const baseAmount = useSelector((state) => state.portfolioSelection.baseAmount)
  const portfoliosName = useSelector((state) => state.portfoliosName.data)
  // HELPERS
  const hasSelectedFunds = () =>
    portfolioSelection.portfolioA.portfolioFunds.length > 0 &&
    portfolioSelection.portfolioB.portfolioFunds.length > 0
  
    const hasSelectedAnyFunds = () =>
      portfolioSelection.portfolioA.portfolioFunds.length > 0 ||
      portfolioSelection.portfolioB.portfolioFunds.length > 0

  const isFundInPortfolio = (targetFund, portfolio) => {
    const targetFundID = targetFund.run || targetFund.fundId
    const targetFundSerie = targetFund.serie || targetFund.shareClassName
    const targetID = `${targetFundID}-${targetFundSerie}`
    const exists = portfolioSelection[portfolio].portfolioFunds.some(
      (currentFund) => {
        const currentFundID = currentFund.run || currentFund.fundId
        const currentFundSerie = currentFund.serie || currentFund.shareClassName
        const currentID = `${currentFundID}-${currentFundSerie}`
        return currentID === targetID
      },
    )
    return exists
  }

  const getPortfolioFundsData = (portfolios) => {
    const fundIdList = []
    Object.keys(portfolios).forEach((portfolioKey) => {
      portfolios[portfolioKey].portfolioFunds.forEach((fund) => {
        fundIdList.push({
          run: +fund.fundId,
          serie: fund.shareClassName,
        })
      })
    })
    const portfolioFundsData = {}
    fundIdList.forEach((fund) => {
      const searchedFunds = []
      rawCatalog.forEach((rawFund) => {
        if (fund.run === +rawFund.run) {
          searchedFunds.push(rawFund)
        }
      })
      portfolioFundsData[fund.run] = searchedFunds
    })
    return portfolioFundsData
  }

  const getPortfolioInfo = () => {
    const totalFundsPortfolioA =
      portfolioSelection.portfolioA.portfolioFunds.length 
    const totalFundsPortfolioB =
      portfolioSelection.portfolioB.portfolioFunds.length
    const totalFundsSelected = totalFundsPortfolioA + totalFundsPortfolioB
    return { totalFundsPortfolioA, totalFundsPortfolioB, totalFundsSelected }
  }

  const getPortfolioTotal = (portfolio) => {
    if (!portfolioSelection[portfolio]) return 0
    return portfolioSelection[portfolio].portfolioFunds.reduce( (acc, curr) => acc + curr.amount, 0 )
  }

  const getPortfolioSelectionTotalAmount = () =>
    getPortfolioTotal("portfolioA") + getPortfolioTotal("portfolioB")

  const getPortfolioTotalWeight = (portfolio) =>
    portfolioSelection[portfolio].portfolioFunds.reduce(
      (acc, curr) => acc + curr.weight,
      0,
    )

  const getReferencePortfolioFundTotalAmount = (portfolioFunds) =>
    portfolioFunds.reduce(
      (acc, curr) => acc + curr.composition.amount.amount,
      0,
    )

  const buildNewPortfolioSelectionByPortfolio = (
    compFundList,
    targetPortfolio,
  ) => {
    const newFundList = compFundList.map((compFund) => ({
      fundId: compFund.fundId,
      fund_name: compFund.fund_name,
      shareClassName: compFund.shareClassName,
      fundProviderId: compFund.fundProviderId,
      prospectusCode: compFund.prospectusCode,
      fundType: compFund.fundType,
      weight: compFund.composition[distributionOption].weight,
      amount: compFund.composition[distributionOption].amount,
    }))
    const newPortfolioData = {
      ...portfolioSelection[targetPortfolio],
      ...{ portfolioFunds: newFundList },
    }
    return {
      ...portfolioSelection,
      ...{ [targetPortfolio]: newPortfolioData },
    }
  }

  const buildNewPortfolioSelectionFromCompositions = (compositions) => {
    const portfolioFundLists = {
      portfolioA: [],
      portfolioB: [],
    }
    Object.keys(compositions).forEach((portfolioKey) => {
      const newPortfolioFunds = []
      compositions[portfolioKey].forEach((compFund) => {
        const newFund = {
          fundId: compFund.fundId,
          fund_name: compFund.fund_name,
          shareClassName: compFund.shareClassName,
          fundProviderId: compFund.fundProviderId,
          prospectusCode: compFund.prospectusCode,
          fundType: compFund.fundType,
          weight: compFund.composition[distributionOption].weight,
          amount: compFund.composition[distributionOption].amount,
        }
        newPortfolioFunds.push(newFund)
      })
      portfolioFundLists[portfolioKey] = newPortfolioFunds
    })
    // DEVOLVER LA LISTA ACTUALIZADA DE FONDOS EN PORTFOLIO SELECTION
    return {
      portfolioA: {
        ...portfolioSelection.portfolioA,
        ...{
          portfolioFunds: portfolioFundLists.portfolioA,
        },
      },
      portfolioB: {
        ...portfolioSelection.portfolioB,
        ...{
          portfolioFunds: portfolioFundLists.portfolioB,
        },
      },
    }
  }

  const buildNewPortfolioSelectionFromCompositionsByKey = (
    compositions,
    targetPortfolio,
  ) => {
    const newPortfolioFunds = []
    // CONSTRUIR LA LISTA DE FONDOS CON LA ESTRUCTURA DEL PORTFOLIO SELECTION
    compositions[targetPortfolio].forEach((compFund) => {
      const newFund = {
        fundId: compFund.fundId,
        fund_name: compFund.fund_name,
        shareClassName: compFund.shareClassName,
        fundProviderId: compFund.fundProviderId,
        prospectusCode: compFund.prospectusCode,
        fundType: compFund.fundType,
        weight: compFund.composition[distributionOption].weight,
        amount: compFund.composition[distributionOption].amount,
      }
      newPortfolioFunds.push(newFund)
    })
    // DEVOLVER LA LISTA ACTUALIZADA DE FONDOS EN PORTFOLIO SELECTION
    return {
      ...portfolioSelection,
      ...{
        [targetPortfolio]: {
          ...portfolioSelection[targetPortfolio],
          ...{
            portfolioFunds: newPortfolioFunds,
          },
        },
      },
    }
  }

  const buildNewPortfolioSelectionFromComposition = ( compositions ) => {
    const newPortfolioSelection = {}
    // CONSTRUIR LA LISTA DE FONDOS CON LA ESTRUCTURA DEL PORTFOLIO SELECTION
    Object.keys(compositions).forEach((portfolioKey) => {
      const portfolioFunds = compositions[portfolioKey];
      const newPortfolioFunds = [];
      if( portfolioFunds.length > 0 ) {
        portfolioFunds.forEach((compFund) => {
          const newFund = {
            fundId: compFund.fundId,
            fund_name: compFund.fund_name,
            shareClassName: compFund.shareClassName,
            fundProviderId: compFund.fundProviderId,
            prospectusCode: compFund.prospectusCode,
            fundType: compFund.fundType,
            weight: compFund.composition[distributionOption].weight,
            amount: compFund.composition[distributionOption].amount,
          }
          newPortfolioFunds.push(newFund)
        });
      }
      newPortfolioSelection[portfolioKey] = {
        ...portfolioSelection[portfolioKey],
        ...{
          portfolioFunds: newPortfolioFunds,
        },
      }
    })
    return newPortfolioSelection;
  }


  const transformToCurrency = (value, targetCurrency, currencyValue) => {
    let newValue;
    if (targetCurrency === 'CLP') {
      newValue = (value * currencyValue)
    } else {
      newValue = (value / currencyValue)
    }
    return newValue;
  }

  // FUNCTIONS
  const addFundToBucket = async (
    fund,
    onMaxFundsReached,
    onFundAlreadyInPortfolio,
    onComplete,
    forceToPortfolio = null,
  ) => {
    let targetPortfolio = activePortfolio
    if (forceToPortfolio !== null) targetPortfolio = forceToPortfolio
    const currentTotalFunds = portfolioComposition[targetPortfolio].length
    const targetPortfolioFunds = portfolioComposition[targetPortfolio]

    if (currentTotalFunds >= maxFundsPerPortfolio.current) {
      onMaxFundsReached()
      return
    }
    // -- HACER LA DISTRIBUCIÓN DE PORCENTAJES DE FONDOS QUE YA EXISTAN EN ALGUN PORTAFOLIO
    // CONTAR CUÁNTOS FONDOS HAY EN PORTAFOLIO, CONSIDERANDO EL QUE SE AGREGARÁ.
    const updatedPortfolioFunds = []
    const totalFunds = targetPortfolioFunds.length + 1

    if (distributionOption === "weight") {
      // DETERMINAR SI HAY FONDOS FIJADOS.
      let totalFundsFixed = 0 // LOS FONDOS FIJADOS SON AQUELLOS DONDE LA PERSONA MODIFICO MANUALMENTE EL PORCENTAJE. NO SE DEBEN MODIFICAR AUTOMÁTICAMENTE.
      let fixedInitialValue = 0 // LA SUMATORIA DE LOS MONTOS QUE ESTAN FIJADOS.
      let totalAmountByAmount = minimumAmmountPerFund // EL TOTAL PARA EL CASO "AMOUNT" (IMPORTANTE PARA QUE TENGA UN VALOR AL SER INGRESADO POR PRIMERA VEZ UN FONDO)
      targetPortfolioFunds.forEach((f) => {
        // DETERMINAR SI ALGUNO DE LOS FONDOS ESTA FIJADO
        if (f.fixedWeight) {
          // SI EL FONDO YA ESTÁ FIJADO (Y NO ES EL FONDO CON EL QUE ESTOY INTERACTUANDO)
          totalFundsFixed += 1 // INCREMENTA EL TOTAL DE FONDOS FIJADOS
          fixedInitialValue += f.composition.weight.weight // INCREMENTA EL PORCENTAJE FIJADO.
        }
        totalAmountByAmount += f.composition.amount.amount
      })

      const unFixedFunds = totalFunds - totalFundsFixed // LOS FONDOS QUE NO ESTÁN FIJADOS
      const distributedWeight = Math.abs(
        (100 - fixedInitialValue) / unFixedFunds,
      ) // CALCULAR DEL PORCENTAJE RESTANTE, CUANTO SE LE ASIGNA A CADA FONDO NO FIJADO.

      // CREAR NUEVA VERSIÓN DEL PORTFOLIOFUNDS CON LOS FONDOS QUE ESTAN EN EL PORTAFOLIO
      targetPortfolioFunds.forEach((currentFund) => {
        // RECREAR EL FONDO QUE SE ESTÁ ITERANDO
        const updatedFund = {
          fundId: currentFund.fundId,
          fund_name: currentFund.fund_name,
          shareClassName: currentFund.shareClassName,
          fundProviderId: currentFund.fundProviderId,
          prospectusCode: currentFund.prospectusCode,
          fundType: currentFund.fundType,
          fixedWeight: currentFund.fixedWeight,
          composition: {
            weight: {
              weight: currentFund.composition.weight.weight,
              amount: currentFund.composition.weight.amount,
            },
            amount: {
              amount: currentFund.composition.amount.amount,
              weight:
                (currentFund.composition.amount.amount * 100) /
                totalAmountByAmount,
            },
          },
        }
        // SI EL FONDO NO TIENE UN PORCENTAJE FIJADO.
        if (!currentFund.fixedWeight) {
          // SI EL FONDO NO ESTÁ FIJADO ASIGNARLE EL MONTO RESTANTE DISTRIBUÍDO, SI ES MENOR A CERO, ASIGNARLE EL MONTO MÍNIMO QUE ES 100.
          const unFixedWeight = distributedWeight < 0 ? 0 : distributedWeight
          // ACTUALIZAR EL MONTO SOLO SI EXISTE UN MONTO BASE
          const unFixedAmount =
            baseAmount > 0
              ? (baseAmount * unFixedWeight) / 100
              : minimumAmmountPerFund
          // ACTUALIZAR SOLO EL WEIGHT DEL FUNDO
          updatedFund.composition.weight = {
            weight: unFixedWeight,
            amount: unFixedAmount,
          }
        }
        updatedPortfolioFunds.push(updatedFund)
      })
      // CREAR EL NUEVO FONDO
      // ! CALCULAR CON MONEDA.
      const newFundAmount = baseAmount
        ? (baseAmount * distributedWeight) / 100
        : minimumAmmountPerFund

      const newFundWeight = totalFunds === 0 ? 100 : distributedWeight

      const newFund = {
        fundId: fund.run,
        fund_name: fund.fund_name,
        shareClassName: fund.serie,
        fundProviderId: fund.fund_provider_id,
        prospectusCode: fund?.prospectusCode,
        fundType: fund.fund_type,
        composition: {
          weight: {
            weight: newFundWeight,
            amount: newFundAmount,
          },
          amount: {
            // PARA LOS FONDOS NUEVOS ASIGNAR VALORES INICIALES PARA EL CASO AMOUNT.
            amount: minimumAmmountPerFund,
            weight: (minimumAmmountPerFund * 100) / totalAmountByAmount,
          },
        },
      }
      // AGREGARLO AL PORTFOLIO ACTUALIZADO
      updatedPortfolioFunds.push(newFund)
    } // FIN WEIGHT

    if (distributionOption === "amount") {
      // DETERMINAR SI HAY FONDOS FIJADOS.
      let totalFundsFixed = 0 // LOS FONDOS FIJADOS SON AQUELLOS DONDE LA PERSONA MODIFICO MANUALMENTE EL PORCENTAJE. NO SE DEBEN MODIFICAR AUTOMÁTICAMENTE.
      let fixedInitialValue = 0 // LA SUMATORIA DE LOS MONTOS QUE ESTAN FIJADOS.
      let totalAmountByAmount = minimumAmmountPerFund // EL TOTAL PARA EL CASO "AMOUNT" (IMPORTANTE PARA QUE TENGA UN VALOR AL SER INGRESADO POR PRIMERA VEZ UN FONDO)
      targetPortfolioFunds.forEach((f) => {
        // DETERMINAR SI ALGUNO DE LOS FONDOS ESTA FIJADO
        if (f.fixedWeight) {
          // SI EL FONDO YA ESTÁ FIJADO (Y NO ES EL FONDO CON EL QUE ESTOY INTERACTUANDO)
          totalFundsFixed += 1 // INCREMENTA EL TOTAL DE FONDOS FIJADOS
          fixedInitialValue += f.composition.weight.weight // INCREMENTA EL PORCENTAJE FIJADO.
        }
        totalAmountByAmount += f.composition.amount.amount
      })

      const unFixedFunds = totalFunds - totalFundsFixed // LOS FONDOS QUE NO ESTÁN FIJADOS
      const distributedWeight = Math.abs(
        (100 - fixedInitialValue) / unFixedFunds,
      ) // CALCULAR DEL PORCENTAJE RESTANTE, CUANTO SE LE ASIGNA A CADA FONDO NO FIJADO.

      // CALCULAR EL MONTO TOTAL ACTUAL DE LA LISTA DE FONDO CONSIDERANDO EL MONTO MÍNIMO CORRESPONDIENTE AL FONDO NUEVO
      const totalAmount =
        getReferencePortfolioFundTotalAmount(targetPortfolioFunds) +
        minimumAmmountPerFund
      // CREAR NUEVA VERSIÓN DEL PORTFOLIO CON LOS FONDOS QUE ESTAN EN EL PORTAFOLIO
      const fundWeightForWeight = 100 / totalFunds

      targetPortfolioFunds.forEach((currentFund) => {
        // RECREAR EL FONDO QUE SE ESTÁ ITERANDO Y RECALCULAR LOS PORCENTAJES SEGUN EL MONTO TOTAL
        const getWeightForWeightCase = currentFund.fixedWeight
          ? currentFund.composition.weight.weight
          : fundWeightForWeight
        const getAmountForWeightCase = baseAmount
          ? (baseAmount * getWeightForWeightCase) / 100
          : currentFund.composition.weight.amount
        const updatedFund = {
          fundId: currentFund.fundId,
          fund_name: currentFund.fund_name,
          shareClassName: currentFund.shareClassName,
          fundProviderId: currentFund.fundProviderId,
          prospectusCode: currentFund.prospectusCode,
          fundType: currentFund.fundType,
          fixedWeight: currentFund.fixedWeight,
          composition: {
            weight: {
              weight: getWeightForWeightCase,
              amount: getAmountForWeightCase,
            },
            amount: {
              amount: currentFund.composition.amount.amount,
              weight:
                (currentFund.composition.amount.amount * 100) / totalAmount,
            },
          },
        }
        // SI EL FONDO NO TIENE UN PORCENTAJE FIJADO.
        if (!currentFund.fixedWeight) {
          // SI EL FONDO NO ESTÁ FIJADO ASIGNARLE EL MONTO RESTANTE DISTRIBUÍDO, SI ES MENOR A CERO, ASIGNARLE EL MONTO MÍNIMO QUE ES 100.
          const unFixedWeight = distributedWeight < 0 ? 0 : distributedWeight
          // ACTUALIZAR EL MONTO SOLO SI EXISTE UN MONTO BASE
          const unFixedAmount =
            baseAmount > 0
              ? (baseAmount * unFixedWeight) / 100
              : minimumAmmountPerFund
          // ACTUALIZAR SOLO EL WEIGHT DEL FUNDO
          updatedFund.composition.weight = {
            weight: unFixedWeight,
            amount: unFixedAmount,
          }
        }
        updatedPortfolioFunds.push(updatedFund)
      })
      // CREAR EL NUEVO FONDO
      const newFundWeightForWeightCase =
        distributedWeight < 0 ? 0 : distributedWeight // 100 / totalFunds,
      const newFundAmountForWeightCase = baseAmount
        ? (baseAmount * newFundWeightForWeightCase) / 100
        : minimumAmmountPerFund

      const newFund = {
        fundId: fund.run,
        fund_name: fund.fund_name,
        shareClassName: fund.serie,
        fundProviderId: fund.fund_provider_id,
        prospectusCode: fund.prospectusCode,
        fundType: fund.fund_type,
        composition: {
          weight: {
            // PARA LOS FONDOS NUEVOS ASIGNAR VALORES INICIALES PARA EL CASO WEIGHT.
            weight: newFundWeightForWeightCase,
            amount: newFundAmountForWeightCase,
          },
          amount: {
            amount: minimumAmmountPerFund,
            weight: (minimumAmmountPerFund * 100) / totalAmount,
          },
        },
      }
      // AGREGARLO AL PORTFOLIO ACTUALIZADO
      updatedPortfolioFunds.push(newFund)
    } // FIN AMOUNT

    // ACTUALIZAR PORTAFOLIO COMPOSITIONS
    const newPortfolioCompositions = {
      ...portfolioComposition,
      ...{ [targetPortfolio]: updatedPortfolioFunds },
    }
    // ACTUALIZAR LA LISTA DE FONDOS EN PORTFOLIO SELECTION
    const newPortfolioSelection =
      buildNewPortfolioSelectionFromCompositionsByKey(
        newPortfolioCompositions,
        targetPortfolio,
      )

    // UPDATE PORTFOLIO COMPOSITIONS
    dispatch(setPortfolioComposition(newPortfolioCompositions))
    dispatch(updatePortfolioSelection(newPortfolioSelection))
    dispatch(updateActivePortfolio(targetPortfolio))

    if (isFundInPortfolio(fund, targetPortfolio)) {
      onFundAlreadyInPortfolio()
    }

    onComplete()
  }

  const updatePortfoliosBaseAmountsByWeight = (amount) => {
    // ESTE METODO SE INVOCA CUANDO SE AGREGA UN MONTO EN EL CAMPO DE MONTO INICIAL. APLICA SOLO PARA CASO WEIGHT
    if (baseAmount > 0) {
      const updatedPortfolioCompositions = {}
      // ITERAR AMBOS PORTAFOLIOS.
      Object.keys(portfolioComposition).forEach((portfolioKey) => {
        // GET PORTFOLIO FUNDS
        const portfolioFunds = portfolioComposition[portfolioKey]
        // ITERAR FONDOS
        const updatedPortfolioFunds = portfolioFunds.map((fund) => {
          // CALCULAR AMOUNT BASADO EN EL WEIGHT.
          const updatedFundAmount = (amount * fund.composition.weight.weight) / 100;
          const newCompositionWeight = {
            ...fund.composition.weight,
            ...{ amount: updatedFundAmount },
          }
          const newCompositions = {
            ...fund.composition,
            ...{ weight: newCompositionWeight },
          }
          return {
            ...fund,
            ...{ composition: newCompositions },
          }
        })
        // ACTUALIZAR ESTRUCTURA DE COMPOSICION
        updatedPortfolioCompositions[portfolioKey] = updatedPortfolioFunds
      })

      const updatedPortfolioSelection =
        buildNewPortfolioSelectionFromCompositions(updatedPortfolioCompositions)

      dispatch(setPortfolioComposition(updatedPortfolioCompositions))
      dispatch(updatePortfolioSelection(updatedPortfolioSelection))
    }
  }

  const updatePortfolioAmounts = useCallback(
    (targetFund, portfolioKey, value) => {
      const targetFundId = targetFund.run || targetFund.fundId
      const targetSerie = targetFund.serie || targetFund.shareClassName

      const portfolioFunds = portfolioComposition[portfolioKey]
      const targetID = `${targetFundId}-${targetSerie}`
      const newPortfolioFunds = portfolioFunds.map((currentFund) => {
        const currentFundID = `${currentFund.fundId}-${currentFund.shareClassName}`
        if (targetID === currentFundID) {
          const fundAmount = {
            ...currentFund.composition.amount,
            ...{ amount: +value },
          }
          return {
            ...currentFund,
            ...{
              composition: {
                ...currentFund.composition,
                ...{ amount: fundAmount },
              },
            },
          }
        }
        return currentFund
      })

      const totalAmount = newPortfolioFunds.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.composition.amount.amount,
        0,
      )
      const updatedPortfolioComposition = newPortfolioFunds.map(
        (currentFund) => {
          const amount = +currentFund.composition.amount.amount
          const weight = amount > 0 ? (amount * 100) / totalAmount : 0
          const fundAmount = {
            amount,
            weight,
          }
          return {
            ...currentFund,
            ...{
              composition: {
                ...currentFund.composition,
                ...{ amount: fundAmount },
              },
            },
          }
        },
      )
      // ACTUALIZAR PORTAFOLIO COMPOSITIONS
      const newPortfolioCompositions = {
        ...portfolioComposition,
        ...{ [portfolioKey]: updatedPortfolioComposition },
      }
      // ACTUALIZAR LA LISTA DE FONDOS EN PORTFOLIO SELECTION
      const newPortfolioSelection =
        buildNewPortfolioSelectionFromCompositionsByKey(
          newPortfolioCompositions,
          portfolioKey,
        )

      // UPDATE PORTFOLIO COMPOSITIONS
      dispatch(setPortfolioComposition(newPortfolioCompositions))
      dispatch(updatePortfolioSelection(newPortfolioSelection))
    },
    [portfolioSelection, dispatch],
  )

  const updatePortfolioWeights = useCallback(
    (targetFund, portfolioKey, value, fundIndex) => {
      const targetFundId = targetFund.run || targetFund.fundId
      const targetSerie = targetFund.serie || targetFund.shareClassName
      const targetID = `${targetFundId}-${targetSerie}`
      const portfolioFunds = portfolioComposition[portfolioKey]
      const totalFunds = portfolioFunds.length
      let totalFundsFixed = 0 // LOS FONDOS FIJADOS SON AQUELLOS DONDE LA PERSONA MODIFICO MANUALMENTE EL PORCENTAJE. NO SE DEBEN MODIFICAR AUTOMÁTICAMENTE.
      let fixedInitialValue = 0 // LA SUMATORIA DE LOS PORCENTAJES QUE ESTAN FIJADOS.

      // SETEAR VALORES NECESARIOS PARA LOS CÁLCULOS
      portfolioFunds.forEach((fund, index) => {
        const currentFundID = `${fund.fundId}-${fund.shareClassName}`
        // SI ES EL FONDO CON EL QUE ESTOY INTERACTUANDO
        if (currentFundID === targetID && index === fundIndex) {
          totalFundsFixed += 1 // INCREMENTA EL TOTAL DE FONDOS FIJADOS
          fixedInitialValue += +value // INCREMENTA EL PORCENTAJE TOTAL DE FONDOS FIJADOS.
        } else if (fund.fixedWeight) {
          // SI EL FONDO YA ESTÁ FIJADO (Y NO ES EL FONDO CON EL QUE ESTOY INTERACTUANDO)
          totalFundsFixed += 1 // INCREMENTA EL TOTAL DE FONDOS FIJADOS
          fixedInitialValue += fund.composition.weight.weight // INCREMENTA EL PORCENTAJE FIJADO.
        }
      })

      const unFixedFunds = totalFunds - totalFundsFixed // LOS FONDOS QUE NO ESTÁN FIJADOS
      const distributedWeight = (100 - fixedInitialValue) / unFixedFunds // CALCULAR DEL PORCENTAJE RESTANTE QUE SE LE ASIGNA A CADA FONDO NO FIJADO.

      // CONSTRUIR NUEVA LISTA DE FONDOS PARA ESTE PORTAFOLIO.
      const newPortfolio = portfolioFunds.map((currentFund, index) => {
        // CONSTRUIR EL FONDO NUEVAMENTE.
        const updatedFund = {
          fundId: currentFund.fundId,
          fund_name: currentFund.fund_name,
          shareClassName: currentFund.shareClassName,
          fundProviderId: currentFund.fundProviderId,
          prospectusCode: currentFund.prospectusCode,
          fundType: currentFund.fundType,
          fixedWeight: currentFund.fixedWeight,
          composition: {
            weight: {
              weight: currentFund.composition.weight.weight,
              amount: currentFund.composition.weight.amount,
            },
            amount: {
              amount: currentFund.composition.amount.amount,
              weight: currentFund.composition.amount.weight,
            },
          },
        }
        const currentFundID = `${currentFund.fundId}-${currentFund.shareClassName}`
        if (currentFundID === targetID && index === fundIndex) {
          // SI EL FONDO QUE ESTOY ITERANDO ES CON EL QUE ESTOY INTERACTUANDO
          updatedFund.composition.weight.weight = +value // ASIGNAR SU WEIGHT AL VALOR INGRESADO EN EL CAMPO DE TEXTO
          updatedFund.fixedWeight = true // MARCARLO COMO FIJADO
          // SI HAY UN MONTO BASE DISPONIBLE, CALCULAR EL VALOR DEL AMOUNT SEGUN EL PORCENTAJE ASIGNADO AL FONDO. SI NO HAY, DEJARLO EN EL MONTO MÍNIMO QUE ES 100.
          updatedFund.composition.weight.amount =
            baseAmount > 0 ? (baseAmount * +value) / 100 : minimumAmmountPerFund
        } else if (!currentFund.fixedWeight) {
          // SI EL FONDO NO ESTÁ FIJADO ASIGNARLE EL MONTO RESTANTE DISTRIBUÍDO, SI ES MENOR A CERO, ASIGNARLE EL MONTO MÍNIMO QUE ES 100.
          const unFixedWeight = distributedWeight < 0 ? 0 : distributedWeight
          updatedFund.composition.weight.weight = unFixedWeight
          updatedFund.composition.weight.amount =
            baseAmount > 0
              ? (baseAmount * unFixedWeight) / 100
              : minimumAmmountPerFund
        }
        return updatedFund
      })

      // ACTUALIZAR PORTAFOLIO COMPOSITIONS
      const newPortfolioCompositions = {
        ...portfolioComposition,
        ...{ [portfolioKey]: newPortfolio },
      }
      // ACTUALIZAR LA LISTA DE FONDOS EN PORTFOLIO SELECTION
      const newPortfolioSelection =
        buildNewPortfolioSelectionFromCompositionsByKey(
          newPortfolioCompositions,
          portfolioKey,
        )
      // UPDATE PORTFOLIO COMPOSITIONS
      dispatch(setPortfolioComposition(newPortfolioCompositions))
      dispatch(updatePortfolioSelection(newPortfolioSelection))
    },
    [portfolioSelection, dispatch],
  )

  const updatePortfolioName = (newName, portfolioKey) => {
    const newData = {
      ...portfolioSelection,
      ...{
        [portfolioKey]: {
          ...portfolioSelection[portfolioKey],
          ...{
            portfolioName: newName,
          },
        },
      },
    }
    dispatch(updatePortfolioSelection(newData))
  }

  const updateFundShareClass = (
    targetFund,
    portfolioKey,
    newShareClass,
    fundIndex,
  ) => {
    const targetID = `${targetFund.fundId}-${targetFund.shareClassName}`
    const portfolioFunds = portfolioComposition[portfolioKey]
    const newPortfolioFunds = portfolioFunds.map((currentFund, index) => {
      const currentFundID = `${currentFund.fundId}-${currentFund.shareClassName}`
      if (targetID === currentFundID && index === fundIndex) {
        return {
          ...currentFund,
          ...{ shareClassName: newShareClass },
        }
      }
      return currentFund
    })

    // ACTUALIZAR PORTAFOLIO COMPOSITIONS
    const newPortfolioCompositions = {
      ...portfolioComposition,
      ...{ [portfolioKey]: newPortfolioFunds },
    }

    // ACTUALIZAR LA LISTA DE FONDOS EN PORTFOLIO SELECTION
    const newPortfolioSelection =
      buildNewPortfolioSelectionFromCompositionsByKey(
        newPortfolioCompositions,
        portfolioKey,
      )
    // UPDATE PORTFOLIO COMPOSITIONS
    dispatch(setPortfolioComposition(newPortfolioCompositions))
    dispatch(updatePortfolioSelection(newPortfolioSelection))
  }

  const deleteFundFromPortfolio = (targetFund, targetPortfolio, fundIndex) => {
    const targetFundID = targetFund.run || targetFund.fundId
    const targetFundSerie = targetFund.serie || targetFund.shareClassName

    const targetID = `${targetFundID}-${targetFundSerie}`


    // RECUPERAR LOS FONDOS DEL PORTAFOLIO QUE NO HAY QUE BORRAR
    const updatedPortfolio = portfolioComposition[targetPortfolio].filter(
      (currentFund, index) => {
        const currentFundID = `${currentFund.fundId}-${currentFund.shareClassName}`
        return currentFundID !== targetID || index !== fundIndex
      },
    )
    const totalFunds = updatedPortfolio.length
    // DETERMINAR EL MONTO TOTAL QUE APLICA SOLO PARA EL CASO AMOUNT
    const totalAmount = updatedPortfolio.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.composition.amount.amount,
      0,
    )
    // DETERMINAR SI HAY FONDOS FIJADOS.
    let totalFundsFixed = 0 // LOS FONDOS FIJADOS SON AQUELLOS DONDE LA PERSONA MODIFICO MANUALMENTE EL PORCENTAJE. NO SE DEBEN MODIFICAR AUTOMÁTICAMENTE.
    let fixedInitialValue = 0 // LA SUMATORIA DE LOS PORCENTAJES QUE ESTAN FIJADOS.

    // DATA PARA DISTRIBUIR
    updatedPortfolio.forEach((f) => {
      // SOLO CASO WEIGHT
      if (f.fixedWeight) {
        totalFundsFixed += 1 // INCREMENTA EL TOTAL DE FONDOS FIJADOS
        fixedInitialValue += f.composition.weight.weight // INCREMENTA EL PORCENTAJE FIJADO.
      }
    })

    const unFixedFunds = totalFunds - totalFundsFixed // LOS FONDOS QUE NO ESTÁN FIJADOS
    const distributedWeight = (100 - fixedInitialValue) / unFixedFunds // CALCULAR DEL PORCENTAJE RESTANTE, CUANTO SE LE ASIGNA A CADA FONDO NO FIJADO.

    // ITERAR LA LISTA DE FONDOS PARA ACTUALIZARLOS
    const updatedPortfolioSelection = updatedPortfolio.map((currentFund) => {
      // RECREAR EL FONDO.
      const updatedFund = {
        ...currentFund,
        ...{
          composition: {
            weight: {
              weight: currentFund.composition.weight.weight,
              amount: currentFund.composition.weight.amount,
            },
            amount: {
              amount: currentFund.composition.amount.amount,
              weight:
                (currentFund.composition.amount.amount * 100) / totalAmount,
            },
          },
        },
      }
      // SI EL FONDO NO TIENE UN PORCENTAJE FIJADO CALCULAR NUEVOS VALORES
      if (!currentFund.fixedWeight) {
        const unFixedWeight = distributedWeight < 0 ? 0 : distributedWeight
        // SI EL FONDO NO ESTÁ FIJADO ASIGNARLE EL MONTO RESTANTE DISTRIBUÍDO, SI ES MENOR A CERO, ASIGNARLE EL MONTO MÍNIMO QUE ES 100.
        updatedFund.composition.weight = {
          weight: unFixedWeight,
          amount: baseAmount
            ? (baseAmount * unFixedWeight) / 100
            : (totalAmount * unFixedWeight) / 100,
        }
      }

      return updatedFund
    })

    // ACTUALIZAR EL PORTFOLIO COMPOSITION
    const newPortfolioCompositions = {
      ...portfolioComposition,
      ...{ [targetPortfolio]: updatedPortfolioSelection },
    }

    // // ACTUALIZAR LA LISTA DE FONDOS EN PORTFOLIO SELECTION
    const newPortfolioSelection = buildNewPortfolioSelectionByPortfolio(
      updatedPortfolioSelection,
      targetPortfolio,
    )

    // // UPDATE PORTFOLIO COMPOSITIONS
    dispatch(setPortfolioComposition(newPortfolioCompositions))
    dispatch(updatePortfolioSelection(newPortfolioSelection))

    dispatch(updateActivePortfolio(targetPortfolio))
  }

  const clearPortfolioA = () => {
    const newSelection = {
      ...portfolioSelection,
      ...{
        portfolioA: {
          portfolioName: "Portafolio A",
          portfolioFunds: [],
          totalDistribution: 0,
        },
      },
    }
    const newComposition = {
      ...portfolioComposition,
      ...{
        portfolioA: [],
      },
    }
    const nameReset = {
      ...portfoliosName,
      ...{
        portfolioA: { portfolioName: "Portafolio A" },
      },
    }
    dispatch(updateDistributionOption("weight"))
    dispatch(updateBaseAmount(null))
    dispatch(enablePortfolioB(false))
    dispatch(updateActivePortfolio("portfolioA"))
    dispatch(updatePortfoliosName(nameReset))
    dispatch(setPortfolioComposition(newComposition))
    dispatch(updatePortfolioSelection(newSelection))
  }

  const removePortfolio = (portfolio) => {
    let newSelection
    let newComposition
    if (portfolio === "portfolioB") {
      newSelection = {
        ...portfolioSelection,
        ...{
          portfolioB: {
            portfolioName: "Portafolio B",
            portfolioFunds: [],
            totalDistribution: 0,
          },
        },
      }
      newComposition = {
        ...portfolioComposition,
        ...{
          portfolioB: [],
        },
      }
      dispatch(
        updatePortfoliosName({
          ...portfoliosName,
          portfolioB: {
            portfolioName: "Portafolio B",
          },
        }),
      )
    }
    if (portfolio === "portfolioA") {
      const cloned = clone(portfolioSelection.portfolioB)
      if (cloned.portfolioName === "Portafolio B") {
        cloned.portfolioName = "Portafolio A"
        dispatch(
          updatePortfoliosName({
            portfolioA: {
              portfolioName: cloned.portfolioName,
            },
            portfolioB: {
              portfolioName: "Portafolio B",
            },
          }),
        )
      }
      newSelection = {
        portfolioA: cloned,
        portfolioB: {
          portfolioName: "Portafolio B",
          portfolioFunds: [],
          totalDistribution: 0,
        },
      }
      newComposition = {
        portfolioA: portfolioComposition.portfolioB,
        portfolioB: [],
      }
    }


    dispatch(enablePortfolioB(false))
    dispatch(updateActivePortfolio("portfolioA"))
    dispatch(updatePortfolioSelection(newSelection))
    dispatch(setPortfolioComposition(newComposition))
  }

  const handleDistributionToggleChange = (selection) => {
    // Si la selección es monto, actualizar los fondos de portfolioSelection con los valores de amount que tenga portfolioComposition para ese fondo.
    const newFundLists = {}
    Object.keys(portfolioComposition).forEach((portfolioKey) => {
      newFundLists[portfolioKey] = portfolioComposition[portfolioKey].map(
        (compFund) => ({
          fundId: compFund.fundId,
          fund_name: compFund.fund_name,
          shareClassName: compFund.shareClassName,
          fundProviderId: compFund.fundProviderId,
          prospectusCode: compFund.prospectusCode,
          fundType: compFund.fundType,
          weight: compFund.composition[selection].weight,
          amount: compFund.composition[selection].amount,
        }),
      )
    })

    const newPortfolioSelection = {
      portfolioA: {
        ...portfolioSelection.portfolioA,
        ...{
          portfolioFunds: newFundLists.portfolioA,
        },
      },
      portfolioB: {
        ...portfolioSelection.portfolioB,
        ...{
          portfolioFunds: newFundLists.portfolioB,
        },
      },
    }
    dispatch(updatePortfolioSelection(newPortfolioSelection))
  }

  const updatePortfolioSelectionByCurrency = async (newCurrency) => {
    if( hasSelectedAnyFunds() ) {
      // console.log("PORTFOLIO SELECTION ANTES: ", portfolioSelection);
      // console.log("PORTFOLIO COMPOSITION ANTES: ", portfolioComposition);
      const requestValorMoneda = await DataFetch(`${urls.API_URL}/advisors/${user.uid}/get_currency_value`, {}, {firebase}, "get");
      const currencyValue = requestValorMoneda.value;

      // TRANSFORMAR VALOR DEL BASE AMOUNT
      const newBaseAmount = transformToCurrency(baseAmount, newCurrency, currencyValue);
      const newPortfolioComposition = {};
      
      const buildPortfolio = (portfolioKey) => {
        const list = [];
        if(portfolioComposition[portfolioKey].length > 0) {
          portfolioComposition[portfolioKey].forEach( (fund) => {
            const newWeightAmount = transformToCurrency(fund.composition.weight.amount, newCurrency, currencyValue);
            const newFundAmount = transformToCurrency(fund.composition.amount.amount, newCurrency, currencyValue);
            const newFund = {
              fundId: fund.fundId,
              fund_name: fund.fund_name,
              shareClassName: fund.shareClassName,
              fundProviderId: fund.fundProviderId,
              prospectusCode: fund.prospectusCode,
              fundType: fund.fundType,
              composition: {
                weight: {
                  weight: fund.composition.weight.weight,
                  amount: newWeightAmount,
                },
                amount: {
                  amount: newFundAmount,
                  weight: fund.composition.amount.weight
                },
              }
            }
            list.push(newFund);
          });
        }
        return list;
      }
      // NO BORRAR
      newPortfolioComposition["portfolioA"] = buildPortfolio("portfolioA");
      newPortfolioComposition["portfolioB"] = buildPortfolio("portfolioB");
      
      // AGREGAR A PORTFOLIO
      const newPortfolioSelection = buildNewPortfolioSelectionFromComposition(newPortfolioComposition);

      // console.log("PORTFOLIO SELECTION DESPUES: ", newPortfolioSelection);
      // console.log("PORTFOLIO COMPOSITION DESPUES: ", newPortfolioComposition);
      
      dispatch(updatePortfolioSelection(newPortfolioSelection));
      dispatch(setPortfolioComposition(newPortfolioComposition))
      dispatch(updateBaseAmount(newBaseAmount));
    }
  }

  return {
    addFundToBucket,
    updatePortfolioWeights,
    getPortfolioInfo,
    getPortfolioTotal,
    getPortfolioSelectionTotalAmount,
    getPortfolioTotalWeight,
    hasSelectedFunds,
    updatePortfolioName,
    updatePortfolioAmounts,
    updateFundShareClass,
    deleteFundFromPortfolio,
    removePortfolio,
    clearPortfolioA,
    isPortfolioBEnabled,
    updatePortfoliosBaseAmountsByWeight,
    handleDistributionToggleChange,
    getPortfolioFundsData,
    updatePortfolioSelectionByCurrency
  }
}

export default usePreviewPanel
