// UI
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/shadcn/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/shadcn/ui/accordion";

import MarkdownRenderer from "@/components/helpers/MarkdownRenderer.jsx";

const FAQSection = ({ FAQ }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg text-blue-500 font-bold">
          Preguntas Frecuentes
        </CardTitle>
        <CardDescription className="text-base">
          Explora o busca por tema en nuestras preguntas frecuentes y encuentra
          la ayuda que necesitas.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-10">
          {FAQ.map((section) => (
            <div key={section.id} className="space-y-4">
              <h3 className="text-base font-bold">{section.title}</h3>
              <Accordion
                type="single"
                collapsible
                className="space-y-3 [&>*]:rounded-[8px]"
              >
                {section.questions.map((questions, i) => (
                  <AccordionItem
                    key={`faq-${section.id}-${i}}`}
                    value={`faq-${section.id}-${i}}`}
                    className="border data-[state=open]:[&>h3]:bg-slate-100 dark:data-[state=open]:[&>h3]:bg-slate-800 overflow-hidden"
                  >
                    <AccordionTrigger
                      id={questions.id}
                      className="px-2 hover:bg-slate-100 dark:hover:bg-slate-800 hover:no-underline"
                    >
                      {questions.q}
                    </AccordionTrigger>
                    <AccordionContent className="p-2">
                      <MarkdownRenderer content={questions.a} />
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default FAQSection;
