import { useState } from "react"
import PropTypes from "prop-types"
import { MinusCircleIcon, ChevronDownIcon } from "@heroicons/react/24/outline"
import { useSelector } from "react-redux"
import Cleave from "cleave.js/react"
import { debounce } from "lodash"

// HOOKS
import usePreviewPanel from "./usePreviewPanel"

// UI
import DragIcon from "../utilities/DragIcon"
import {
  displayString,
  formatAmount,
  formatToFloat,
  formatAmountByCurrency,
} from "../utilities/functions"
import PreviewFundSeriesSelector from "./PreviewFundSeriesSelector"
import useTracking from "../../../hooks/useTracking"

function PreviewFund({ fund, fundIndex, currentPortfolio, setDragOriginArea }) {
  // TRACK
  const trackEvent = useTracking()
  // STATE
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  // STORE
  const catalogByFunds = useSelector((state) => state.catalogByFunds.data)
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  )
  const baseAmount = useSelector((state) => state.portfolioSelection.baseAmount)
  const currency = useSelector((state) => state.proposalType.currency)

  // HOOKS
  const {
    updatePortfolioAmounts,
    updatePortfolioWeights,
    deleteFundFromPortfolio,
    updateFundShareClass,
  } = usePreviewPanel()

  // CALLBACKS
  const handleDragStart = (event) => {
    event.stopPropagation()
    setDragOriginArea(currentPortfolio)
    event.dataTransfer.setData("fund/id", fund.fundId)
    event.dataTransfer.setData("fund/name", fund.fund_name)
    event.dataTransfer.setData("fund/shareClass", fund.shareClassName)
    event.dataTransfer.setData("portfolio/origin", currentPortfolio)

    event.dataTransfer.effectAllowed = "move"

    event.dataTransfer.dropEffect = "copy"
  }
  const handleDragEnd = () => {
    setDragOriginArea("")
  }

  const handleFundRemove = () => {
    trackEvent({
      action: "REMOVE_FUND_PORTFOLIO",
      timestamp: Date.now(),
      type: "CLICK",
      data: `${fund.fundId} | ${fund.fund_name} | ${fund.shareClassName} | ${currentPortfolio}`,
    })
    deleteFundFromPortfolio(fund, currentPortfolio, fundIndex)
  }

  const handleInputChange = debounce((event) => {
    const value = event.target.rawValue === "" ? 0 : event.target.rawValue
    // DEPRECADO
    // const regex = /^[0-9\b]+$/
    const decimalRegex = /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/

    if (value === "" || decimalRegex.test(value)) {
      if (distributionOption === "weight") {
        trackEvent({
          action: "CHANGE_FUND_WEIGHT_PORTFOLIO",
          timestamp: Date.now(),
          type: "INPUT",
          data: `${fund.fundId} => ${value} | ${currentPortfolio}`,
        })
        updatePortfolioWeights(fund, currentPortfolio, value, fundIndex)
      } else {
        trackEvent({
          action: "CHANGE_FUND_AMOUNT_PORTFOLIO",
          timestamp: Date.now(),
          type: "INPUT",
          data: `${fund.fundId} => ${value} | ${currentPortfolio}`,
        })
        updatePortfolioAmounts(fund, currentPortfolio, value, fundIndex)
      }
    }
  }, 150)

  const handleSerieChange = (fundSerie) => {
    trackEvent({
      action: "CHANGE_FUND_SERIE_PORTFOLIO",
      timestamp: Date.now(),
      type: "SELECT",
      data: `${fund.fundId} => ${fundSerie} | ${currentPortfolio}`,
    })
    updateFundShareClass(fund, currentPortfolio, fundSerie, fundIndex)
  }

  const getContainerCols = () => {
    if (distributionOption === "weight") {
      if (baseAmount > 0) {
        return "grid-cols-[1fr_60px_100px_20px]"
      }
      return "grid-cols-[1fr_70px_20px]"
    }
    return "grid-cols-[1fr_130px_50px_20px]"
  }

  const formatInput = (baseAmount) => {
    const roundedAmount = Math.round(baseAmount)
    if (!baseAmount || baseAmount === 0) {
      return ""
    } else {
      const baseAmountString = baseAmount.toString().split(".")
      if (baseAmountString[1] && currency !== "CLP") {
        return baseAmountString[0] + "," + baseAmountString[1]
      }
      else {
        return roundedAmount
      }
    }
  }

  return (
    <div
      className="flex items-center justify-between gap-2 active:bg-slate-200 dark:active:bg-slate-800"
      title={`${displayString(fund.fund_name)} | Serie ${fund.shareClassName}`}
    >
      <div
        className={`w-full grid items-center justify-start gap-2 relative
        ${getContainerCols()}
      `}
      >
        <div className="relative flex items-center justify-between gap-1">
          <div
            draggable
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            className="flex items-center gap-2 cursor-grab active:cursor-grabbing"
          >
            <span className="grow-1 w-2 flex-none scale-75">
              <DragIcon />
            </span>
            <p className="text-sm text-slate-900 dark:text-slate-50 line-clamp-1 break-words w-full">
              {displayString(fund.fund_name)}
            </p>
          </div>
          <button
            type="button"
            className="relative grow-1 w-[100px] ml-2 flex-none flex items-center justify-between gap-1 bg-slate-50 dark:bg-slate-600 border border-slate-300 dark:border-transparent p-1 text-center text-xs rounded-md"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="max-w-[calc(100%-12px)] overflow-hidden whitespace-nowrap">
              {fund.shareClassName}
            </span>
            <ChevronDownIcon className="w-3 h-3" />
            <PreviewFundSeriesSelector
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              catalogByFunds={catalogByFunds[fund.fundId]}
              selectedSerie={fund.shareClassName}
              handleSerieChange={handleSerieChange}
            />
          </button>
        </div>

        {distributionOption === "weight" ? (
          <>
            <div className="flex items-stretch justify-start">
              <Cleave
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                value={
                  fund.weight === 0
                    ? 0
                    : formatToFloat(+fund.weight, 2, "").replace(/[.,]00$/, "")
                }
                onChange={handleInputChange}
                className="w-full bg-slate-50 dark:bg-slate-600 px-1 py-1 text-right text-sm border-t border-l border-b border-r-0 border-slate-300 dark:border-transparent rounded-tl-md rounded-bl-md focus:border-slate-300 focus:outline-none focus:ring-0"
              />
              <span className="bg-slate-50 dark:bg-slate-600 px-1 py-1 text-center text-sm rounded-tr-md rounded-br-md border-t border-r border-b border-l-0 border-slate-300 dark:border-transparent">
                %
              </span>
            </div>

            {baseAmount > 0 && (
              <p
                title={fund.amount}
                className={`text-right ${`${Math.round(fund.amount)}`.length > 8
                    ? "text-xs"
                    : "text-sm"
                  }`}
              >
                <span className="text-xxs">{currency}$</span>
                {formatAmountByCurrency(fund.amount, currency)}
              </p>
            )}
          </>
        ) : (
          <>
            <div className="flex items-stretch justify-start">
              <span className="bg-muted text-muted-foreground px-1 py-1 text-center text-sm rounded-tl-md rounded-bl-md border border-slate-300 dark:border-transparent">
                {currency}$
              </span>
              <Cleave
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                value={formatInput(+fund.amount)}
                onChange={handleInputChange}
                className="w-full bg-slate-50 dark:bg-slate-600 px-1 py-1 text-left text-sm border-t border-r border-b border-l-0 border-slate-300 dark:border-transparent rounded-tr-md rounded-br-md focus:border-slate-300 focus:outline-none focus:ring-0"
              />
            </div>
            <p className="text-right text-sm">
              {formatToFloat(+fund.weight, 2, "").replace(/[.,]00$/, "")}%
            </p>
          </>
        )}

        <button
          type="button"
          onClick={handleFundRemove}
          title={`Eliminar ${displayString(fund.fund_name)} | ${fund.shareClassName
            }`}
          className=""
        >
          <MinusCircleIcon className="w-4.5 h-4.5 text-blue-500 dark:text-blue-400" />
        </button>
      </div>
    </div>
  )
}

PreviewFund.propTypes = {
  fund: PropTypes.shape({
    fundId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    fund_name: PropTypes.string.isRequired,
    shareClassName: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
  }).isRequired,
  fundIndex: PropTypes.number.isRequired,
  currentPortfolio: PropTypes.string.isRequired,
  setDragOriginArea: PropTypes.func.isRequired,
}

export default PreviewFund
