import PropTypes from "prop-types"

// STORE
import { useSelector } from "react-redux"

// HOOKS
import usePreviewPanel from "../PreviewPanel/usePreviewPanel"

// COMPONENTS
import DistributionToggle from "./distributionToggle"
import AnalysisScreenerPortfolio from "./AnalysisScreenerPortfolio"
import PortfolioTotalComparisonAlert from "../PortfolioTotalComparisonAlert/PortfolioTotalComparisonAlert"
import ToggleSectionButtonOutlined from "./ToggleSectionButtonOutlined"

// UTILS
import RenderIf from "../../helpers/RenderIf"
import { CURRENCIES_MAP } from "../utilities/dataMaps"

function AnalysisScreener({ onOfComponent, modalInfo }) {
  // HOOKS
  const { getPortfolioInfo } = usePreviewPanel()
  // STORE
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  const currency = useSelector((state) => state.proposalType.currency);
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  )

  // AMOUNT WARNING
  const getPortfolioAmount = (portfolioFunds) =>
    portfolioFunds.reduce((acc, curr) => acc + curr.amount, 0)
  const shouldShowWarning = () =>
    getPortfolioAmount(portfolios.portfolioA?.portfolioFunds) !==
    getPortfolioAmount(portfolios.portfolioB?.portfolioFunds)

  return (
    <div id="costos">
      <div className="mb-10 w-full flex items-center justify-between">
        <div className="flex items-center gap-4">
          <h2 className="analysis-box-header">
            { `${getPortfolioInfo().totalFundsPortfolioB === 0 ? "Portafolio" : "Portafolios"} en ${CURRENCIES_MAP[currency]}`}
          </h2>
          <ToggleSectionButtonOutlined
            component="tac"
            action={onOfComponent}
            tooltip={[
              "Ocultar costos en propuesta",
              "Mostrar costos en propuesta",
            ]}
          />
        </div>
        <div className="flex items-center justify-end gap-2">
          <h3 className="text-sm">Distribución</h3>
          <DistributionToggle />
        </div>
      </div>

      {distributionOption === "amount" &&
        portfolios.portfolioB?.portfolioFunds.length > 0 && (
          <PortfolioTotalComparisonAlert shouldShow={shouldShowWarning()} />
        )}

      <div className="flex flex-col gap-8">
        <RenderIf isTrue={portfolios.portfolioA.portfolioFunds.length}>
          <AnalysisScreenerPortfolio
            portfolioKey="portfolioA"
            modalInfo={modalInfo}
          />
        </RenderIf>
        <RenderIf isTrue={portfolios.portfolioB.portfolioFunds.length}>
          <AnalysisScreenerPortfolio
            portfolioKey="portfolioB"
            modalInfo={modalInfo}
          />
        </RenderIf>
      </div>
    </div>
  )
}

AnalysisScreener.propTypes = {
  onOfComponent: PropTypes.func.isRequired,
  modalInfo: PropTypes.shape({
    setFactSheetModalMessage: PropTypes.func,
    setFactSheetModalOpen: PropTypes.func,
  }).isRequired,
}

export default AnalysisScreener
