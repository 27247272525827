import { useSelector, useDispatch } from "react-redux"

// STORE
import { updateAnalysisDataConfig } from "../../../store/analysisDataConfigSlice"

// HOOKS
import useTracking from "../../../hooks/useTracking"

// COMPONENTS
import AnalysisLogo from "./AnalysisLogo"
import AnalysisEquityDebt from "./AnalysisEquityDebt";

// UTILS
import { formatDataInfoDate, formatMonthFullYear } from "../utilities/functions"
import { CURRENCIES_MAP } from "../utilities/dataMaps";

function AnalysisInfo({
  onOfComponent,
  summaryData,
  handleTakeScreenshot
}) {
  // TRACK
  const trackEvent = useTracking()
  // STORE
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const currency = useSelector((state) => state.proposalType.currency);
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig)

  // CALLBACKS
  const handleChange = (e) => {
    const { name, value } = e.target

    switch (name) {
      case "comments":
        trackEvent({
          action: "ADD_COMMENTS_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        })
        break
      case "objective":
        trackEvent({
          action: "ADD_OBJECTIVE_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        })
        break
      case "clientName":
        trackEvent({
          action: "ADD_CLIENT_NAME_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        })
        break
      case "clientProfile":
        trackEvent({
          action: "ADD_CLIENT_PROFILE_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        })
        break
      case "clientInvestmentType":
        trackEvent({
          action: "ADD_CLIENT_PROFILE_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        })
        break
      default:
        trackEvent({
          action: "ADD_TITLE_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        })
    }
    dispatch(
      updateAnalysisDataConfig({
        ...analysisDataConfig.data,
        [name]: value,
      }),
    )
  }

  return (
    <div className="flex flex-col items-stretch justify-start gap-5">
      <div className="flex items-start justify-between gap-10">
        <div className="max-w-[1000px] grow">
          <input
            type="text"
            name="title"
            value={analysisDataConfig.data.title}
            placeholder={`Título de la propuesta en ${CURRENCIES_MAP[currency].toLowerCase()}`}
            onChange={handleChange}
            className="w-full text-lg rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500"
          />
          <div className="mt-4 grid grid-cols-5 gap-4">
            <div>
              <p className="text-xs font-bold">Cliente:</p>
              <input
                type="text"
                name="clientName"
                value={analysisDataConfig.data.clientName}
                placeholder="Nombre del cliente"
                onChange={handleChange}
                className="w-full px-2 py-1 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500"
              />
            </div>
            <div>
              <p className="text-xs font-bold">Perfil:</p>
              <input
                type="text"
                name="clientProfile"
                value={analysisDataConfig.data.clientProfile}
                placeholder="Perfil del cliente"
                onChange={handleChange}
                className="w-full px-2 py-1 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500"
              />
            </div>
            <div>
              <p className="text-xs font-bold">Horizonte de inversión:</p>
              <input
                type="text"
                name="clientInvestmentType"
                value={analysisDataConfig.data.clientInvestmentType}
                placeholder="Horizonte de inversión"
                onChange={handleChange}
                className="w-full px-2 py-1 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500"
              />
            </div>
            <div className="text-xs">
              <p className="font-bold">Asesor:</p>
              <p className="py-1">{`${user.name} ${user.lastname}`}</p>
            </div>
            <div className="text-xs">
              <p className="font-bold">Fecha:</p>
              <p className="py-1">{formatDataInfoDate(Date.now())}</p>
            </div>
          </div>
        </div>
        <AnalysisLogo />
      </div>
      <div>
        <p className="mb-2 text-base font-bold">
          Objetivo del portafolio recomendado
        </p>
        <textarea
          name="objective"
          onChange={handleChange}
          value={analysisDataConfig.data.objective}
          placeholder="Objetivo de la propuesta"
          className="w-full px-2 py-1 h-24 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500 resize-none"
        />
      </div>
      <div>
        <p className="mb-2 text-base font-bold">
          {`Comentario de la recomendación  |  ${formatMonthFullYear(
            Date.now(),
          )}`}
        </p>
        <textarea
          name="comments"
          onChange={handleChange}
          value={analysisDataConfig.data.comments}
          placeholder="Comentario de la propuesta"
          className="w-full px-2 py-1 h-24 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500 resize-none"
        />
      </div>
      { summaryData && Object.keys(summaryData).length > 0 && (
        <AnalysisEquityDebt
          data={summaryData}
          onOfComponent={onOfComponent}
          handleTakeScreenshot={handleTakeScreenshot}
        />
      )}
    </div>
  )
}

export default AnalysisInfo
