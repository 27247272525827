import { useState, useEffect } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

// HOOKS
import { useDispatch, useSelector } from "react-redux";
import useColorCompany from "../../../../hooks/useColorCompany";

// STORE
import { updateCharstData } from "../../../../store/chartsDataSlice";

// COMPONENTS
import LineChart from "../../../charts/LineChart";
import ReturnsTableAPV from "../../../charts/ReturnsTable/ReturnsTableAPV";
// UI
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/shadcn/ui/tooltip";

import { formatDataInfoDate } from "../../utilities/functions";

// UTILS
import { getDifferenceInMonths } from "../../../../utils/DateCalculations";
import { parseISO } from "date-fns";

function SectionReturnsAPV({
  timePeriod,
  chartsData,
  periodList,
  portfolioExistenceStatus,
}) {
  // HOOKS
  const { getColorsCompany } = useColorCompany();

  // MEMO
  const isDownSample = true
  const apvExcludePeriods = [6]

  const currency = useSelector((state) => state.proposalType.currency);

  const selectedDate = periodList?.find(
    (item) => item.value === timePeriod,
  )?.date;
  const [firstSelected, setFirstSelected] = useState(
    typeof selectedDate?.[0] === "string"
      ? parseISO(selectedDate?.[0])
      : selectedDate?.[0],
  );
  const [lastSelected, setLastSelected] = useState(
    typeof selectedDate?.[1] === "string"
      ? parseISO(selectedDate?.[1])
      : selectedDate?.[1],
  );

  useEffect(() => {
    setFirstSelected(
      typeof selectedDate?.[0] === "string"
        ? parseISO(selectedDate?.[0])
        : selectedDate?.[0],
    );
    setLastSelected(
      typeof selectedDate?.[1] === "string"
        ? parseISO(selectedDate?.[1])
        : selectedDate?.[1],
    );
  }, [selectedDate]);

  const ageInMonths = getDifferenceInMonths(firstSelected, lastSelected);
  const hasLessThanOneYear = ageInMonths < 12 && ageInMonths >= 6;
  // STORE
  const dispatch = useDispatch();

  const getLastDate = () => {
    const firstElement = Object.keys(chartsData?.performanceChartData)[0];
    const firstElementArr = chartsData?.performanceChartData[firstElement];
    if (firstElementArr.length > 0) {
      const { data } = firstElementArr[0];
      const total = data.length;
      const lastDate = data[total - 1];
      return (
        <p className="text-xs text-slate-500 dark:text-slate-400">
          Al {formatDataInfoDate(lastDate.date)}
        </p>
      );
    }
    return null;
  };

  return (
    <>
      <div className="w-full grid grid-cols-2 items-center gap-4 mb-12">
        <div className="space-y-1">
          <div className="flex items-center">
            <h2 className="text-base font-bold">{currency === "USD" ? `Rentabilidad en dólares` : `Rentabilidad real en pesos`}</h2>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="text-primary">
                  <InformationCircleIcon className="w-5 h-5 shrink-0" />
                </TooltipTrigger>
                {currency === "CLP" ? (<TooltipContent side="bottom" className="bg-muted text-muted-foreground max-w-48">
                  El cálculo de rentabilidad considera todos los eventos de capital (dividendos, repartos, etc) y los valores están deflactados por la U.F. (rentabilidad real).
                </TooltipContent>) : (<TooltipContent side="bottom" className="bg-muted text-muted-foreground max-w-48">
                  El cálculo de rentabilidad considera todos los eventos de capital (dividendos, repartos, etc) y los valores están calculados en dólares.
                </TooltipContent>)}
              </Tooltip>
            </TooltipProvider>
          </div>
          {getLastDate()}
        </div>
        <select
          name="TimePeriod"
          value={timePeriod}
          onChange={(event) => {
            dispatch(
              updateCharstData({
                ...chartsData,
                timePeriod: parseInt(event.target.value, 10),
              }),
            );
          }}
          className="justify-self-end bg-slate-100 dark:bg-slate-700 w-24 px-2 py-1 text-xs rounded-md text-slate-900 dark:text-slate-50 !outline-none border border-slate-400 dark:border-slate-600"
        >
          {periodList.map((tp) => {
            let option = null;
            if (hasLessThanOneYear && tp.value === 6) {
              option = (
                <option value={6} key="6 meses">
                  6 meses
                </option>
              );
            } else if (!apvExcludePeriods.includes(tp.value)) {
              option = (
                <option value={tp.value} key={tp.key}>
                  {tp.key}
                </option>
              );
            } else {
              return null;
            }
            return option;
          })}
        </select>
      </div>

      {chartsData?.performanceChartData && (
        <div className="w-full mb-12">
          {/* <NoReturnsNotice emptyDates={chartsData?.nullDates} /> */}
          <LineChart
            performanceData={chartsData?.performanceChartData[timePeriod]}
            downSample={isDownSample}
            emptyDates={chartsData?.nullDates}
            colors={getColorsCompany()}
            showLegend={false}
          />
        </div>
      )}
      {chartsData?.portfoliosTableReturns && (
        <div className="w-full mt-5">
          <ReturnsTableAPV
            data={chartsData?.portfoliosTableReturns}
            portfolioExistenceStatus={portfolioExistenceStatus}
            colors={getColorsCompany()}
          />
          {currency === "CLP" ? (<p className="text-xxs text-slate-600 dark:text-slate-400 flex gap-1">
            <InformationCircleIcon className="w-4 h-4" /> Los datos corresponden
            a rentabilidad real anualizada.
          </p>) : (<p className="text-xxs text-slate-600 dark:text-slate-400 flex gap-1">
            <InformationCircleIcon className="w-4 h-4" /> Los datos corresponden
            a rentabilidad en dólares.
          </p>)}

        </div>
      )}
    </>
  );
}

export default SectionReturnsAPV;
