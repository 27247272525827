import { useState, useContext, Fragment, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import version from "../../../version.json";
import { Button } from "@/components/shadcn/ui/button";

// CONTEXT
import FirebaseContext from "../../../firebase/FirebaseContext";

// HOOKS
import useOnClickOutside from "../../../hooks/useOnClickOutside";

// ASSETS AND COMPONENTS
import LOGO from "../../../assets/images/logos/app_logo_header.svg";
import PortfoliosButton from "../buttons/PortfoliosButton";
import RenderIf from "../../helpers/RenderIf";

// CONSTANTS
import * as APP_ROUTES from "../../../routes/APP_ROUTES";
import SETTINGS from "../../../settings/settings";

// COMPONENTS
import { displayString } from "../utilities/functions";
import FreshDeskButton from "../FreshDeskButton/FreshDeskButton";
import ColorSchemeToggle from "../ColorSchemeToggle/ColorSchemeToggle";
import ProposalTypeDropdown from "../filters/ProposalTypeDropdown";
import { formatHeader } from "../utilities/functions";
import { parseAPIDate } from "@/utils/DateCalculations";
import { LoadTermsAndConditions } from "../utilities/LoadTermsAndConditions";
import { cn } from "@/components/shadcn/lib/utils";
import ProposalCurrencyDropdown from "../filters/ProposalCurrencyDropdown";

const NavigationTab = ({ route, label }) => (
  <NavLink
    to={route}
    className={cn(
      "h-full grid place-content-center px-4 text-slate-50 text-sm border-x border-transparent aria-[current=page]:bg-blue-600/50 aria-[current=page]:border-blue-600/80 hover:bg-blue-700/50 transition-colors duration-300",
    )}
  >
    {label}
  </NavLink>
);

function Header() {
  // REF
  const subMenuRef = useRef(null);
  // STATE
  const [isSubMenuOpen, setIsSubmenuOpen] = useState(false);
  // CONTEXT
  const { doSignOut } = useContext(FirebaseContext);
  // STORE
  const user = useSelector((state) => state.user.user);
  const appData = useSelector((state) => state.appData.data);
  // HOOKS
  const navigate = useNavigate();
  const location = useLocation();
  useOnClickOutside(subMenuRef, () => setIsSubmenuOpen(false));
  const formatDays = (days) => {
    if (days != 1) {
      return `${days} días`;
    } else {
      return `${days} día`;
    }
  };

  return (
    <header className="fixed top-0 w-full z-[60] -mt-[2px] font-normal">
      {user.accountStatus === "ON_TRIAL" && (
        <div className="w-full h-8 px-2 sm:px-4 lg:px-8 inline-flex gap-6 items-center text-xs text-slate-50 bg-blue-800">
          <p className="">
            Tu periodo de prueba termina el{" "}
            <strong className="font-bold">
              {formatHeader(parseAPIDate(user.expireTrialDate))} (
              {formatDays(user.remainingTrialDays)})
            </strong>
          </p>
          <Button asChild size="xs">
            <Link to={APP_ROUTES.SUBSCRIPTION_PAGE} className="uppercase">
              Contratar
            </Link>
          </Button>

          <FreshDeskButton
            label={"Envíanos feedback aquí "}
            className="text-slate-200 hover:text-slate-200/80"
          />
        </div>
      )}
      <section
        className="h-14 flex items-center justify-between px-2 gap-2 bg-blue-500
          sm:px-4
          lg:px-8
        "
      >
        <div className="inline-flex gap-10 items-center justify-start">
          <Link
            to={APP_ROUTES.SCREENER}
            className="flex flex-col items-end justify-center w-[130px] grow-0"
          >
            <img
              src={`${LOGO}?v=0.7`}
              alt="Elevest"
              className="flex shrink-0 self-center w-auto h-full"
            />
            <RenderIf isTrue={appData.isBeta}>
              <span className="text-xs leading-none uppercase text-slate-50">
                Beta
              </span>
            </RenderIf>
          </Link>
          <div className="inline-flex gap-5">
            {[APP_ROUTES.SCREENER, APP_ROUTES.ANALYSIS].includes(location.pathname,) && (
              <>
                <ProposalTypeDropdown />
                <ProposalCurrencyDropdown />
              </>
            )}
            {location.pathname === APP_ROUTES.SCREENER && <PortfoliosButton />}
          </div>
        </div>

        <nav className="relative h-full flex items-center justify-end space-x-4">
          <div className="inline-flex h-full items-stretch">
            <NavigationTab route={APP_ROUTES.SCREENER} label="Catálogo" />

            <NavigationTab
              route={APP_ROUTES.MY_ANALISIS}
              label="Mis Propuestas"
            />

            <NavigationTab route={APP_ROUTES.HELP_CENTER} label="Centro de Ayuda" />

          </div>

          <div className="inline-flex gap-4 items-center">
            <FreshDeskButton
              className="text-slate-50 hover:text-slate-200"
              size="sm"
            />
            <div className="flex gap-10 items-stretch">
              <div className="text-sm leading-none grid place-content-center transition-color duration-300 text-slate-200 font-light cursor-pointer">
                <button
                  id="headerSubmenuToggle"
                  type="button"
                  className="w-8 h-8 bg-blue-600 text-xs font-semibold leading-0 uppercase grid place-content-center rounded-full hover:bg-blue-600/80 hover:text-slate-50"
                  onClick={() => setIsSubmenuOpen(!isSubMenuOpen)}
                >
                  {`${user?.name?.charAt(0) ?? ""}${
                    user?.lastname?.charAt(0) ?? ""
                  }`}
                </button>
              </div>
            </div>
          </div>

          <Transition
            as={Fragment}
            show={isSubMenuOpen}
            enter="transform transition duration-500 overflow-hidden"
            enterFrom="opacity-0 h-0"
            enterTo="opacity-100 h-min"
            leave="transform duration-500 transition ease-in-out overflow-hidden"
            leaveFrom="opacity-100 h-min"
            leaveTo="opacity-0 h-0"
          >
            <div
              ref={subMenuRef}
              className="w-[220px] font-light absolute top-14 mt-1 flex flex-col gap-3 px-5 py-4 right-0 bg-blue-50 dark:bg-slate-800 rounded-md z-[60] isolate"
            >
              <div className="absolute p-1 right-0 -top-1 mt-1">
                <button
                  type="button"
                  className="flex items-center gap-1 p-1 hover:bg-slate-50/20 rounded-md transition-all duration-300"
                  onClick={() => setIsSubmenuOpen(false)}
                >
                  <XMarkIcon className="w-4 h-4" />
                </button>
              </div>
              <div className="text-center">
                <h2>{`${user.name} ${user.lastname}`}</h2>
                {user.company && (
                  <h3 className="text-sm opacity-75">
                    {displayString(user?.company.name)}
                  </h3>
                )}
              </div>
              <div className="-z-10 text-sm flex flex-col gap-[1px] items-stretch justify-center rounded-md">
                <NavLink
                  to={APP_ROUTES.USER}
                  className="px-5 py-3 text-left transition-color duration-300 bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-slate-200 rounded-t-md"
                  onClick={() => setIsSubmenuOpen(false)}
                >
                  Mi Perfil
                </NavLink>

                {/* <NavLink
                  to={APP_ROUTES.HELP_CENTER}
                  className="px-5 py-3 text-left transition-color duration-300 bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-slate-200"
                  onClick={() => setIsSubmenuOpen(false)}
                >
                  Centro de Ayuda
                </NavLink> */}

                <RenderIf
                  isTrue={user.role.includes(
                    SETTINGS.USER_PROFILES.APP_ADMINISTRATOR,
                  )}
                >
                  <NavLink
                    to={APP_ROUTES.ADMIN}
                    className="px-5 py-3 text-left transition-color duration-300 bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-slate-200"
                    onClick={() => setIsSubmenuOpen(false)}
                  >
                    Admin
                  </NavLink>
                </RenderIf>

                <button
                  type="button"
                  className="px-5 py-3 text-left transition-color duration-300 bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-slate-200 rounded-bl-md rounded-br-md"
                  onClick={() => {
                    doSignOut().then(() => {
                      setIsSubmenuOpen(false);
                      navigate("/", { replace: true });
                    });
                  }}
                >
                  Salir
                </button>
              </div>
              <div>
                <ColorSchemeToggle />
                <button
                  onClick={() => LoadTermsAndConditions()}
                  className="text-xs block m-auto text-slate-500 dark:text-slate-400 hover:text-blue-400 mb-2"
                >
                  Términos y Condiciones
                </button>
                {version && (
                  <p className="text-xxs flex align-center justify-center text-slate-500 dark:text-slate-400">{`versión: ${version.tag}`}</p>
                )}
              </div>
            </div>
          </Transition>
        </nav>
      </section>
    </header>
  );
}

export default Header;
