import { InformationCircleIcon } from "@heroicons/react/24/outline"

// UI
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/shadcn/ui/tooltip";


// STORE
import { useSelector } from "react-redux"

import { formatToInteger } from "../utilities/functions"

// CONSTANT
import { CURRENCIES_MAP } from "@/components/ui/utilities/dataMaps";


function ScreenerStatus({ isFiltered, searchTerm, totalResultsFound }) {
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  const currency = useSelector((state) => state.proposalType.currency);

  return (
    <div className="text-sm flex items-center justify-between gap-1 relative z-[45] isolate">
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger className="text-primary">
            <InformationCircleIcon className="w-5 h-5 shrink-0" />
          </TooltipTrigger>
          <TooltipContent side="bottom" className="bg-muted text-muted-foreground max-w-48">
            {!isAPV ? (
              <div className="space-y-2">
                <h4 className="font-semibold">
                  Fondos en catálogo general
                </h4>
                <p>
                  {`Los montos de los fondos incluidos en este catálogo están
                  expresados en ${CURRENCIES_MAP[currency]} (${currency})`}
                </p>
              </div>
            ) : (
              <div className="space-y-2">
                <h4 className="font-semibold">Fondos en catálogo APV</h4>
                <p>
                  {`Las rentabilidades presentadas en este catálogo son
                  anualizadas, netas de costos y comisiones, y ajustadas por la
                  variación de la Unidad de Fomento (UF), lo que refleja el
                  rendimiento real del fondo.`}
                  {/* CURRENCIES_MAP[currency]} (${currency})`} */}
                </p>
              </div>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      {!isFiltered && !searchTerm ? (
        <p className="text-base">
          <span>Régimen {isAPV ? "APV" : "General"}: </span>
          <span>{formatToInteger(totalResultsFound)}</span>
        </p>
      ) : (
        <p className="text-base">
          <span>{formatToInteger(totalResultsFound)} </span>
          <span>
            {totalResultsFound === 1
              ? `fondo ${isAPV ? "APV" : ""} encontrado`
              : `fondos ${isAPV ? "APV" : ""} encontrados`}
          </span>
        </p>
      )}
    </div>
  )
}

export default ScreenerStatus
