import { useState, useEffect } from "react";
import { parseISO } from "date-fns";
// HOOKS
import useColorCompany from "../../../../hooks/useColorCompany";

// COMPONENTS
import LineChart from "../../../charts/LineChart";
import ReturnsTable from "../../../charts/ReturnsTable/ReturnsTable";
import ReturnDatePickerWithSelect from "src/components/ui/ReturnDatePickerWithSelect/ReturnDatePickerWithSelect";

import { formatReturnsDate } from "../../utilities/functions";
import { calculateRelativeDateFrom } from "../../../../utils/DateCalculations";
import useChartDataHandlers from "../../../../hooks/useChartDataHandlers";
import ReturnDatePicker from "../../ReturnDatePicker/ReturnDatePicker";
import { InformationCircleIcon } from "@heroicons/react/24/outline"
// UI
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/shadcn/ui/tooltip";

// CONSTANT
import { CURRENCIES_MAP } from "@/components/ui/utilities/dataMaps";

// STORE
import { useSelector } from "react-redux"

function SectionReturns({
  timePeriod,
  chartsData,
  periodList,
  portfolioExistenceStatus,
}) {
  // HOOKS
  const { getColorsCompany } = useColorCompany();
  // MEMO
  const isDownSample = true;
  // STORE
  const currency = useSelector((state) => state.proposalType.currency);

  const minDate =
    typeof chartsData?.returnsDates?.[0] === "string"
      ? parseISO(chartsData?.returnsDates?.[0])
      : chartsData?.returnsDates?.[0];
  const maxDate =
    typeof chartsData?.returnsDates?.[1] === "string"
      ? parseISO(chartsData?.returnsDates?.[1])
      : chartsData?.returnsDates?.[1];
  const minDateForClosingDate = calculateRelativeDateFrom(maxDate, 2);

  const selectedDate = periodList?.find(
    (item) => item.value === timePeriod,
  )?.date;
  const selectedTimePeriod = periodList?.find(
    (item) => item.value === timePeriod,
  );
  const [firstSelected, setFirstSelected] = useState(
    typeof selectedDate?.[0] === "string"
      ? parseISO(selectedDate?.[0])
      : selectedDate?.[0],
  );
  const [lastSelected, setLastSelected] = useState(
    typeof selectedDate?.[1] === "string"
      ? parseISO(selectedDate?.[1])
      : selectedDate?.[1],
  );

  const periodToDisplay = {
    "5 Años": "de los últimos 5 años",
    "3 Años": "de los últimos 3 años",
    "1 Año": " del último año",
    YTD: "desde inicio de año",
    "6 meses": "de los últimos 6 meses",
    "3 meses": "de los últimos 3 meses",
    "1 mes": "del último mes",
  };

  useEffect(() => {
    setFirstSelected(
      typeof selectedDate?.[0] === "string"
        ? parseISO(selectedDate?.[0])
        : selectedDate?.[0],
    );
    setLastSelected(
      typeof selectedDate?.[1] === "string"
        ? parseISO(selectedDate?.[1])
        : selectedDate?.[1],
    );
  }, [selectedDate]);

  const { handleStartDateChange, handleEndDateChange, handleSelector } =
    useChartDataHandlers(
      firstSelected,
      lastSelected,
      setFirstSelected,
      setLastSelected,
    );

  const getLastDate = () => {
    return timePeriod === 0 ? (
      <p className="text-xs font-bold ">
        Rentabilidad desde el {formatReturnsDate(firstSelected)}
      </p>
    ) : (
      <p className="text-xs">
        <span className="font-semibold">
          Rentabilidad {periodToDisplay[selectedTimePeriod.key]}{" "}
        </span>
      </p>
    );
  };

  const tableTitle = () => {
    return (
      <p className="text-xs font-semibold pb-4">
        Rentabilidad histórica al {formatReturnsDate(lastSelected)}
      </p>
    );
  };

  return (
    <>
      <div className="w-full">
        <div className="flex flex-between justify-between">
          <div className="flex items-center">
            <h2 className="text-base font-bold">Rentabilidad nominal en {CURRENCIES_MAP[currency]}</h2>
            <div className="relative group border-none rounded-md w-8 h-8 grid place-content-center">
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger className="text-primary">
                    <InformationCircleIcon className="w-5 h-5 shrink-0" />
                  </TooltipTrigger>
                  <TooltipContent side="bottom" className="bg-muted text-muted-foreground max-w-48">
                    El cálculo de rentabilidad considera todos los eventos de capital (dividendos, repartos, etc) y está calculado en {CURRENCIES_MAP[currency]} ({currency})
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>

          <div className="flex justify-end items-center gap-4">
            <p className="text-xs text-slate-500 dark:text-slate-400">
              {" "}
              Información al:{" "}
            </p>
            <ReturnDatePicker
              value={lastSelected}
              onChange={handleEndDateChange}
              minDate={minDateForClosingDate}
              maxDate={maxDate}
            />
          </div>
        </div>
      </div>
      {chartsData?.performanceChartData[timePeriod] && (
        <div>
          <div className="col-span-2 justify-between flex gap-4 py-4">
            {getLastDate()}
            <ReturnDatePickerWithSelect
              valueSelect={timePeriod}
              onChangeSelect={(event) => {
                handleSelector(event);
              }}
              onChangeDateFrom={handleStartDateChange}
              onChangeDateTo={handleEndDateChange}
              periodList={periodList}
              firstReturnDate={firstSelected}
              lastReturnDate={lastSelected}
              minDateFrom={minDate}
              MaxDateTo={maxDate}
              isActiveSelect={timePeriod === 0}
            />
          </div>
          <div className="w-full mb-12">
            <LineChart
              performanceData={chartsData?.performanceChartData[timePeriod]}
              downSample={isDownSample}
              emptyDates={chartsData?.nullDates}
              colors={getColorsCompany()}
              showLegend={true}
            />
          </div>
        </div>
      )}
      {chartsData?.portfoliosTableReturns && (
        <div className="w-full">
          {tableTitle()}
          <ReturnsTable
            data={chartsData?.portfoliosTableReturns}
            portfolioExistenceStatus={portfolioExistenceStatus}
            colors={getColorsCompany()}
          />
        </div>
      )}
    </>
  );
}

export default SectionReturns;
