// STORE
import { useSelector, useDispatch } from "react-redux";
import {
  togglePreviewPanel,
  // setOverviewPanelOpen,
} from "../../../store/portfolioPanelSlice";
// HOOKS
import usePreviewPanel from "../PreviewPanel/usePreviewPanel";
import { Button } from "@/components/shadcn/ui/button";
import { cn } from "@/components/shadcn/lib/utils";

function PortfoliosButton() {
  // HOOKS
  const { getPortfolioInfo } = usePreviewPanel();
  // STORE
  const dispatch = useDispatch();
  const panelOpen = useSelector((state) => state.portfolioPanel.isPreviewOpen);
  // HANDLER
  const handleButtonClick = () => {
    // dispatch(setOverviewPanelOpen(false))
    dispatch(togglePreviewPanel(!panelOpen));
  };

  return (
    <div className="space-y-1">
        <p className="text-xs sr-only">
          Vista previa
        </p>
      <Button
        type="button"
        variant="primary"
        title="Previsualizar análisis y ver fondos seleccionados"
        className={cn(
          "w-8 h-8 md:w-auto uppercase rounded-md flex items-center leading-0 justify-between gap-2 md:gap-4 px-3 py-2 text-xss md:text-xs text-primary-foreground transition-colors duration-500 ease-in-out bg-blue-600 border-blue-400 border",
          {"hidden":getPortfolioInfo().totalFundsSelected === 0}
        )} 
        onClick={() => handleButtonClick()}
      >
        <span className="hidden md:inline text-xxs lg:text-xs">
          Vista previa
        </span>
        <span className="w-5 h-5 rounded-full md:text-blue-500 place-content-center font-semibold md:grid md:bg-white">
          {getPortfolioInfo().totalFundsSelected}
        </span>
      </Button>
    </div>
  );
}

export default PortfoliosButton;
