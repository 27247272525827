import { Search, X } from "lucide-react";

const FAQSearch = ({
  searchTerm,
  setSearchTerm,
  onSearchChange,
  onKeyDown,
}) => {
  return (
    <div className="w-full inline-flex border-slate-300 dark:border-slate-700 border focus-within:border-blue-500 px-6 py-2 rounded-lg bg-white dark:bg-slate-900">
      <input
        type="text"
        className="grow text-base font-sans border-none focus:border-none focus:outline-none focus:ring-0 placeholder:font-medium px-0 bg-transparent"
        placeholder="Busca en el Centro de Ayuda"
        value={searchTerm}
        onChange={onSearchChange}
        onKeyDown={onKeyDown}
      />
      <button
        type="button"
        className="grow-0"
        onClick={() => searchTerm !== "" && setSearchTerm("")}
      >
        {searchTerm !== "" ? (
          <X className="w-5 h-5" />
        ) : (
          <Search className="w-5 h-5" />
        )}
      </button>
    </div>
  );
};

export default FAQSearch;
