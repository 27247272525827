import PortfolioManager from "./PortfolioManager"

class PortfolioManagerFactory {
  static getPortfolioManagerInstance(currency) {
    if (PortfolioManagerFactory.portfolioManager) {
      PortfolioManagerFactory.portfolioManager.changeCurrency(currency)
      return PortfolioManagerFactory.portfolioManager
    }
    PortfolioManagerFactory.portfolioManager = new PortfolioManager(currency)
    return PortfolioManagerFactory.portfolioManager
  }
}
export default PortfolioManagerFactory
