import { useState } from "react";
import FAQSearch from "@/components/ui/HelpCenter/FAQSearch";
import FAQSection from "@/components/ui/HelpCenter/FAQSection.jsx";
import IssueReportSection from "@/components/ui/HelpCenter/IssueReportSection";
import VideosSection from "@/components/ui/HelpCenter/VideosSection";
import { debounce } from "lodash";
import { FAQ } from "@/data/FAQs/FAQ";
import ScrollToTopButton from "@/components/ui/ScrollToTopButton/ScrollToTopButton";
import { cn } from "@/components/shadcn/lib/utils";

function HelpCenterPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const handleSearch = debounce((term) => {
    const questions = [];
    FAQ.forEach((item) =>
      item.questions.forEach((question) => {
        if (
          question.q.toLowerCase().includes(term.toLowerCase()) ||
          question.a.toLowerCase().includes(term.toLowerCase())
        ) {
          questions.push(question);
        }
      }),
    );
    setFilteredQuestions(questions);
  }, 300);

  const onSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    if (term.length > 3) {
      handleSearch(term);
    }
  };

  const onKeyDown = (e) => {
    if (filteredQuestions.length === 0) return;
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredQuestions.length - 1 ? prevIndex + 1 : 0,
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : filteredQuestions.length - 1,
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0) {
          const selectedItem = filteredQuestions[highlightedIndex];
          handleSelect(selectedItem);
        }
        break;
      case "Escape":
        e.preventDefault();
        clearSearch();
    }
  };

  const handleSelect = (q) => {
    // console.log(q);
    const element = document.querySelector(`#${q.id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      element.click();
    }
    clearSearch();
  };

  const clearSearch = () => {
    setSearchTerm("");
    highlightedIndex(-1);
  }

  return (
    <main className="h-full min-h-[calc(100vh-5px)] pt-14 bg-slate-50 dark:bg-slate-800">
      <div className="container mx-auto py-14 space-y-10">
        <div className="space-y-5 px-4 sm:px-0">
          <h2 className="text-2xl text-blue-500 font-bold">Centro de ayuda</h2>
          <div className="relative">
            <FAQSearch
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              onSearchChange={onSearchChange}
              onKeyDown={onKeyDown}
            />
            {filteredQuestions.length > 0 && searchTerm !== "" && (
              <div className="z-10 absolute top-14 rounded-lg left-0 w-full bg-white dark:bg-slate-900 shadow-xl divide-y divide-slate-300 dark:divide-slate-700 border">
                {filteredQuestions.map((q, index) => (
                  <button
                    type="button"
                    className={cn(
                      "w-full text-left px-6 py-3 block hover:bg-slate-100 dark:hover:bg-slate-800",
                      {
                        "bg-slate-100 dark:bg-slate-800":
                          index === highlightedIndex,
                      },
                    )}
                    key={q.id}
                    onClick={() => handleSelect(q)}
                  >
                    {q.q}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="px-4 space-y-10 md:space-y-0 md:px-0 md:grid grid-cols-3 gap-10 items-start">
          <div className="col-span-2 space-y-10">
            <VideosSection />
            <FAQSection FAQ={FAQ} />
          </div>
          <IssueReportSection />
        </div>
      </div>
      <ScrollToTopButton />
    </main>
  );
}

export default HelpCenterPage;
