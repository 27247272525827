import PropTypes from "prop-types"

// STORE
import { useSelector } from "react-redux"

// HOOKS
import usePreviewPanel from "./usePreviewPanel"

// UTILS
import {
  formatToFloat,
  formatAmount,
  formatAmounts,
  formatAmountByCurrency,
} from "../utilities/functions"

function PreviewPortfolioTotals({ portfolioKey }) {
  // STORE
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  )
  const baseAmount = useSelector((state) => state.portfolioSelection.baseAmount)
  const currency = useSelector((state) => state.proposalType.currency);

  // HOOKS
  const { getPortfolioTotalWeight, getPortfolioTotal } = usePreviewPanel()

  // FUNCTIONS
  const getContainerCols = () => {
    if (distributionOption === "weight") {
      if (baseAmount > 0) {
        return "grid-cols-[1fr_60px_100px_20px]"
      }
      return "grid-cols-[1fr_70px_20px]"
    }
    return "grid-cols-[1fr_130px_50px_20px]"
  }

  return (
    <div
      className={`mt-1.5 text-right text-xs grid gap-2
        ${getContainerCols()}
      `}
    >
      <strong className="inline-block">Total: </strong>
      {distributionOption === "weight" && (
        <>
          <span
            className={`font-bold
              ${Math.round(getPortfolioTotalWeight(portfolioKey)) > 100
                ? "text-red-800 dark:text-red-300"
                : "text-slate-900 dark:text-slate-50"
              }`}
          >
            {formatToFloat(
              Math.round(getPortfolioTotalWeight(portfolioKey)),
              2,
              "",
            ).replace(/[.,]00$/, "")}
            %
          </span>
          {baseAmount && getPortfolioTotal(portfolioKey) > 0 && (
            <span
              className={`font-bold
            ${Math.floor(getPortfolioTotal(portfolioKey)) > baseAmount
                  ? "text-red-800 dark:text-red-300"
                  : "text-slate-900 dark:text-slate-50"
                }`}
            >
              {currency === 'USD' ? "USD" : "CLP"}${formatAmountByCurrency(getPortfolioTotal(portfolioKey), currency)}
            </span>
          )}
        </>
      )}
      {distributionOption === "amount" && (
        <>
          <span className="font-bold text-left">
            <span className="text-xxs">
              {currency === 'USD' ? "USD" : "CLP"}
            </span>
            ${formatAmountByCurrency(getPortfolioTotal(portfolioKey), currency, 9)}
          </span>
          <span
            className={`font-bold
            ${Math.floor(getPortfolioTotalWeight(portfolioKey)) > 100
                ? "text-red-800 dark:text-red-300"
                : "text-slate-900 dark:text-slate-50"
              }`}
          >
            {formatToFloat(
              +getPortfolioTotalWeight(portfolioKey),
              2,
              "",
            ).replace(/[.,]00$/, "")}
            %
          </span>
        </>
      )}
    </div>
  )
}

PreviewPortfolioTotals.propTypes = {
  portfolioKey: PropTypes.oneOf(["portfolioA", "portfolioB"]).isRequired,
}

export default PreviewPortfolioTotals
