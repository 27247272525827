import * as Sentry from "@sentry/browser";
import { DataFrame, toJSON } from "danfojs"
import {
  calculateAccumulatedReturn,
  calculateMinMaxLosses,
} from "./ReturnsCalculations"
import {
  calculateRelativeDateFrom,
  calculateYTDDateFrom,
  getFixedMonthOrDate,
} from "./DateCalculations"
import { INITIAL_PERIODLIST, INITIAL_PERIODLIST_APV } from "./constants"

class ShareClass {
  constructor(
    fundId,
    shareClassName,
    returnsFirstDay,
    returnsLastDay,
    returnsDF,
    exposures,
    issuers,
    shareClassGeneralInfo,
    fundGeneralInfo,
  ) {
    this.fundId = fundId
    this.shareClassName = shareClassName
    this.shareClassId = ShareClass.createShareClassId(fundId, shareClassName)
    this.returnsFirstDay = returnsFirstDay
    this.returnsLastDay = returnsLastDay
    this.returnsDF = returnsDF.copy()
    this.returnsLagoon = this.getLagoonDates()
    this.exposures = exposures
    this.issuers = issuers
    this.shareClassGeneralInfo = shareClassGeneralInfo
    this.fundGeneralInfo = fundGeneralInfo
    this.initialPeriodList = INITIAL_PERIODLIST
  }

  static createShareClassId(fundId, shareClassName) {
    return `${fundId}-${shareClassName}`
  }

  getLagoonDates() {
    const dates = []
    const ret = this.returnsDF.copy()
    const retJson = toJSON(ret, { format: "column" })
    retJson.forEach((row) => {
      if (row.value == null) {
        dates.push(row.date)
      }
    })
    return dates
  }

  getReturnsByDates(initDate, lastDate) {
    const ret = this.getReturnsDF().copy()
    const retJson = toJSON(ret, { format: "column" })
    const initialIndex = retJson.findIndex((row) => row.date === initDate)
    const lastDateIndex = retJson.findIndex((row) => row.date === lastDate)
    if (lastDateIndex === -1 || initialIndex === -1) {
      return []
    }
    else {
      const newData = retJson.slice(initialIndex, lastDateIndex + 1)
      const newDataDF = new DataFrame(newData)
      return newDataDF
    }
  }

  getReturnsDF() {
    const ret = this.returnsDF.copy()
    const retJson = toJSON(ret, { format: "column" })
    const retFormatted = new DataFrame(retJson)
    return retFormatted
  }

  getShareClassId() {
    return this.shareClassId
  }

  getAccumulatedReturns() {
    const accReturns = calculateAccumulatedReturn(this.getReturnsDF().copy())
    const formattedAccReturns = toJSON(accReturns, {
      format: "column",
    })
    return formattedAccReturns
  }

  calcAccRet(name, initialDate, lastDate = 0, tPeriod = 0) {
    // calculate dates frame with initial date and tperiod
    // crop this.getReturnsDF, from initialDate to lastDate
    // get the accumulated returns with the dates
    try {
      let periodFirstPerformanceDate = null
      // YTD special case
      if (tPeriod === -2) {
        periodFirstPerformanceDate = calculateYTDDateFrom(lastDate)
        if (periodFirstPerformanceDate < initialDate) {
          periodFirstPerformanceDate = initialDate
        }
      } else {
        // calculate the corresponding date of the time period
        periodFirstPerformanceDate = calculateRelativeDateFrom(
          lastDate,
          tPeriod,
        )
      }
      const ret = this.getReturnsDF().copy()
      const pFPDtring = `${periodFirstPerformanceDate.getFullYear()}-${getFixedMonthOrDate(
        periodFirstPerformanceDate.getMonth() + 1,
      )}-${getFixedMonthOrDate(periodFirstPerformanceDate.getDate())}`
      const retD = toJSON(ret, { format: "column" })
      const initialIndex = retD.findIndex((row) => row.date === pFPDtring)
      const newData = retD.slice(initialIndex)
      const newDataDF = new DataFrame(newData)
      const acc = calculateAccumulatedReturn(newDataDF)
      const colAddDiff = acc.value.add(-1).mul(100)

      // replace value column with calculated value
      const formattedDF = newDataDF.copy()
      formattedDF.addColumn("value", colAddDiff, { inplace: true })
      const formattedData = toJSON(formattedDF, { format: "column" })
      return [{ name, data: formattedData }]
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      throw e
    }
  }

  getTableReturnsData(lastDate, is_apv = false) {
    try {
      let periodFirstPerformanceDate = null
      const lastDateDate = new Date(lastDate.split("-")[0], lastDate.split("-")[1] - 1, lastDate.split("-")[2])
      const initialDate = new Date(
        this.returnsFirstDay.split("-")[0],
        this.returnsFirstDay.split("-")[1] - 1,
        this.returnsFirstDay.split("-")[2],
      )
      const pl = is_apv ? INITIAL_PERIODLIST_APV : INITIAL_PERIODLIST
      const returnsTableData = {}
      pl.forEach((period) => {
        // YTD special case
        if (period.value === -2) {
          periodFirstPerformanceDate = calculateYTDDateFrom(lastDateDate)
          if (periodFirstPerformanceDate < initialDate) {
            periodFirstPerformanceDate = initialDate
          }
        } else {
          // calculate the corresponding date of the time period
          periodFirstPerformanceDate = calculateRelativeDateFrom(
            lastDateDate,
            period.value,
          )
        }
        const pFPDtring = `${periodFirstPerformanceDate.getFullYear()}-${getFixedMonthOrDate(
          periodFirstPerformanceDate.getMonth() + 1,
        )}-${getFixedMonthOrDate(periodFirstPerformanceDate.getDate())}`
        const ret = this.getReturnsByDates(pFPDtring, lastDate)
        if (ret.length === 0) {
          returnsTableData[period.key] = 0
        }
        else {
          const AccRet = calculateAccumulatedReturn(ret, 0)
          const AccJson = toJSON(AccRet, {
            format: "column",
          })
          const lastData = AccJson[AccJson.length - 1]
          if(is_apv) {
            returnsTableData[period.key] = ((1 + lastData.value / 100.0) ** (12 / period.value) - 1) * 100.0
          }
          else  {
            returnsTableData[period.key] = lastData.value
          }
        }
      })
      return returnsTableData
    }
    catch (e) {
      Sentry.captureException(e);
      console.log(e)
      throw e
    }
  }

  getReturnsStatistics(maxMinProfitAndLossesWindows) {
    try {
      const rawRet = this.getReturnsDF().copy()
      const accReturns = calculateAccumulatedReturn(rawRet)
      const formattedAccReturns = toJSON(accReturns, {
        format: "column",
      })
      const maxMinProfitAndLosses = calculateMinMaxLosses(
        accReturns.copy().value,
        maxMinProfitAndLossesWindows,
      )
      return { maxMinProfitAndLosses, formattedAccReturns }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e)
      throw e
    }
  }
}
export default ShareClass
