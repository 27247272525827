// AUTHENTICATION
export const HOME = "/";
export const LANDING = "/landing";
export const LOGIN = "/login";
export const PASSWORD_FORGET = "/password-forget";
export const PASSWORD_RESET = "/password-reset";
export const REGISTER_PAGE = "/register";
export const AUTH_ACTIONS = "/auth-actions";
export const REVOKED_SESSIONS = "/revoked-sessions";
export const CONTACT_PAGE = "/contact";
export const REQUEST_DEMO = "/request-demo";
export const TRIAL_EXPIRED = "/trial-expired";
export const SUBSCRIPTION_EXPIRED = "/subscription-expired";
export const SUBSCRIPTION_PAGE = "/subscribe";

// MANAGE ACCOUNT
export const USER = "/mi-perfil";
export const PASSWORD_CHANGE = "/password-change";
export const MY_ANALISIS = "/mis-analisis/";

// ADMIN
export const ADMIN = "/administracion";
export const USERS = "/usuarios";
export const COMPANIES = "/companias";
export const EDIT_USER = "/admin-usuario";
export const EDIT_COMPANY = "/admin-compania";
export const EVENT_LOGS = "/listado-eventos";
export const STATISTICS = "/estadisticas";

// CONTAINER PAGE
export const SCREENER = "/screener";
export const ANALYSIS = "/analysis";
export const PUBLIC = "/public-analysis/:userId/:analysisId";
export const PUBLIC_PDF = "/pdf-analysis/:userId/:analysisId";
export const PREVIEW = "/preview";
export const CALCULATIONS = "/calculations";
export const ADVISORS = "/advisors";
export const HELP_CENTER = "/help-center";

// CLIENT PAGES
export const CLIENT_GOAL = "/objetivo/:clientId/:goalId";

// APP SPECIFIC PAGES
export const HELP = "/help";
export const NOT_FOUND = "/not-found";
export const UNAUTHORIZED = "/unauthorized";

export const LIST_COMPONENTS = "/list-components";

// ERRORS
export const ERROR = "/error";
