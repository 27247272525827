import { useState, useEffect } from 'react';
import { ArrowUp, LoaderCircle, Search } from "lucide-react"

function ScreenerAdvancedSearch({
  advancedSearchValue,
  setAdvancesSearchValue,
  advancedSearchResponse,
  handleAdvancedSearch,
  isAdvancedSearchFetching,
}) {
  const [responseText, setResponseText] = useState(null)

  useEffect(() => {
    if (advancedSearchResponse !== "") {
      let text = {}
      // run es "asdasd" - No hay fondos que cumplan la condición
      // asset class contiene ("Balanceados") y (COUNTRY CL es 100) - No hay fondos que cumplan la condición
      // (CURRENCY CLF > 80)
      const responseParts = advancedSearchResponse.split(" - ");
      if( responseParts.length > 1 ) {
          // Error
          text.result = "No encontramos resultados para el criterio: ",
        text.criterio = responseParts[0];
      } else {
        // OK
        text.result = "Búsqueda exitosa. Encontramos resultados para el criterio:",
        text.criterio = responseParts[0];
      }
      setResponseText(text);
    } else {
      setResponseText(null);
    }
  }, [advancedSearchResponse])

  const handleSearch = (value) => {
    handleAdvancedSearch(value);
  }

  return (
    <div className="relative w-full">
      <div className="w-full inline-flex gap-2 items-center border-slate-300 dark:border-slate-700 border focus-within:border-blue-500 px-4 py-1 rounded-lg bg-white dark:bg-slate-900">
        <p className="text-sm">¿Qué necesitas encontrar?</p>
        <input
          type="text"
          className="grow text-xs font-sans border-none focus:border-none focus:outline-none focus:ring-0 placeholder:font-medium px-0 bg-transparent"
          placeholder="Ej: Fondos balanceados con exposición a UF"
          value={advancedSearchValue}
            onChange={(event) => {
              setAdvancesSearchValue(event.target.value)
            }}
            onKeyDown={(event) => {
              handleAdvancedSearch(event)
            }}
        />
        <button
          className="w-8 h-8 grid place-content-center shrink-0 bg-muted hover:bg-primary rounded-full text-muted-foreground hover:text-primary-foreground transition-colors duration-300"
          title="Filtro avanzado"
          onClick={(event) => handleSearch(event)}
        >
          { isAdvancedSearchFetching ? (
            <LoaderCircle className="w-4 h-4 animate-spin" />
          ) : (
            <Search className="w-4 h-4" />
          )}
        </button>
      </div>
      {responseText && (
        <p className="absolute mt-1 top-10 left-0 text-muted-foreground text-xs">
          {responseText.result} <em>{responseText.criterio}</em>
        </p>
      )}
    </div>
  )
}

export default ScreenerAdvancedSearch
