import { subMonths, differenceInMonths, differenceInDays, parseISO } from "date-fns";
// SENTRY
import * as Sentry from "@sentry/browser";

const getDifferenceInMonths = (lastDate, firstDate) =>
  differenceInMonths(lastDate, firstDate);

const getDifferenceInDays = (startDate, EndDate) =>
  differenceInDays(startDate, EndDate);

const calculateRelativeDateFrom = (referenceDate, months) => {
  try {
    if (typeof referenceDate === "string") {
      return subMonths(parseISO(referenceDate), months);
    }
    return subMonths(referenceDate, months);
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    throw e;
  }
};

const calculateYTDDateFrom = (referenceDate) => {
  try {
    if (typeof referenceDate === "string") {
      return new Date(parseISO(referenceDate).getFullYear() - 1, 11, 31);
    }
    return new Date(referenceDate.getFullYear() - 1, 11, 31);
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    throw e;
  }
};

const parseAPIDate = (apiDate) => {
  // this function return Date from API Date format: yyyy-mm-dd
  try {
    return new Date(
      apiDate.split("-")[0],
      apiDate.split("-")[1] - 1,
      apiDate.split("-")[2],
    );
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    throw e;
  }
};

const getFixedMonthOrDate = (value) => {
  if (value < 10) {
    return `0${value}`;
  }
  return value;
};

const getDateToCompareFormat = (date) =>
  `${date.getFullYear()}-${getFixedMonthOrDate(
    date.getMonth() + 1,
  )}-${getFixedMonthOrDate(date.getDate())}`;

export {
  parseAPIDate,
  calculateRelativeDateFrom,
  calculateYTDDateFrom,
  getFixedMonthOrDate,
  getDateToCompareFormat,
  getDifferenceInMonths,
  getDifferenceInDays,
};
