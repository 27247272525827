import { useState, useEffect } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/ui/dialog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/shadcn/ui/card";
import { Play } from "lucide-react";

// import LOGO from "@/assets/videos/01-poster.png";
import poster_descubre from "@/assets/videos/01-poster.png";
import poster_analiza from "@/assets/videos/02-poster.png";
import poster_comunica from "@/assets/videos/03-poster.png";
import poster_personaliza from "@/assets/videos/04-poster.png";
import video_descubre from "@/assets/videos/01-welcome-descubre.mp4";
import video_analiza from "@/assets/videos/02-welcome-analiza.mp4";
import video_comunica from "@/assets/videos/03-welcome-comunica.mp4";
import video_personaliza from "@/assets/videos/04-welcome-personaliza.mp4";

const VIDEO_DATA = [
  {
    id: "descubre",
    title: "Descubre",
    descripcion:
      "Descubre fondos en forma rápida y precisa utilizando filtros y búsqueda avanzada.",
    src: video_descubre,
    poster: poster_descubre,
  },
  {
    id: "analiza",
    title: "Analiza",
    descripcion:
      "Analiza tus portafolios y ajusta tu selección  visualizando la información a tiempo real.",
    src: video_analiza,
    poster: poster_analiza,
  },
  {
    id: "comunica",
    title: "Comunica",
    descripcion: "Comunica tu visión de mercado a tus clientes, con tu imagen.",
    src: video_comunica,
    poster: poster_comunica,
  },
  {
    id: "personaliza",
    title: "Personaliza",
    descripcion:
      "Descubre fondos en forma rápida y precisa utilizando filtros y búsqueda avanzada.",
    src: video_personaliza,
    poster: poster_personaliza,
  },
];

const VideosSection = () => {
  const [selectedSection, setSelectedSection] = useState(null);

  return (
    <>
      <Card className="shadow-none">
        <CardHeader>
          <CardTitle className="text-lg text-blue-500 font-bold">
            Construye tus propuestas y dales tu identidad.
          </CardTitle>
          <CardDescription className="text-base">
            Estamos aquí para ayudarte a crear tus mejores propuestas de
            inversión. Descubre lo fácil que es siguiendo estos{" "}
            <strong className="font-bold">simples pasos</strong>.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4 md:space-y-0 md:grid grid-cols-2 gap-4">
            {VIDEO_DATA.map((video) => (
              <div
                key={video.id}
                className="bg-blue-50 dark:bg-slate-800 rounded-lg p-6 space-y-5"
              >
                <div className="space-y-1">
                  <h3 className="text-center font-bold text-xl">
                    {video.title}
                  </h3>
                  <p className="text-center font-sm">{video.descripcion}</p>
                </div>
                <div className="w-full h-[150px] relative flex flex-col justify-between">
                  <img
                    src={poster_descubre}
                    alt={video.title}
                    className="w-full h-full object-cover"
                  />
                  <button
                    type="button"
                    className="w-full h-full bg-white/25 dark:bg-slate-900/10 grid place-content-center absolute top-0 left-0 group"
                    onClick={() => setSelectedSection(video)}
                  >
                    <span className="bg-white dark:bg-slate-900 rounded-full w-10 h-10 grid place-content-center group-hover:shadow-lg">
                      <Play className="w-5 h-5" />
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Dialog
        open={!!selectedSection}
        onOpenChange={(isOpen) => !isOpen && setSelectedSection(null)}
      >
        <DialogContent className="w-1/2 max-w-[1440px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              {selectedSection && selectedSection.title}
            </DialogTitle>
            <DialogDescription>
              {selectedSection && selectedSection.descripcion}
            </DialogDescription>
          </DialogHeader>
          {selectedSection && (
            <div className="w-full">
              <video controls className="w-full h-auto" muted>
                <source src={selectedSection.src} type="video/mp4" />
              </video>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideosSection;
