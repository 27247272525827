
import PropTypes from "prop-types"
import { scaleBand, scaleLinear, max } from "d3"
import {
  minMaxChartDataPropTypes,
  minMaxChartConfig,
} from "../../../propTypes/AppPropTypes"

// UTILS
import addOpacityToHexColor from "../../../utils/addOpacityToHexColor"
import {
  formatAmount,
  formatAmounts,
  formatToFloat,
} from "../../ui/utilities/functions"

// STYLES
import styles from "./MinMaxBarChart.module.css"

// HOOKS
import useColorScheme from "../../../hooks/useColorScheme"


function MinMaxBarChartSVG({
  data,
  yScaleDomain,
  dataXDomain,
  chartConfig,
  colors,
  distributionOption,
  baseAmount,
}) {
  // HOOKS
  const [colorScheme] = useColorScheme()
  // SCALES
  const xScale = scaleLinear()
    .domain([0, max(dataXDomain)])
    .range([0, chartConfig.barGroupWidth])
  const yScale = scaleBand()
    .domain(yScaleDomain.map((v, i) => i))
    .range([0, chartConfig.barGroupHeight])
  const minColorPalette = [
    addOpacityToHexColor(colors[0], 0.5),
    addOpacityToHexColor(colors[1], 0.5),
  ]

  const calculateMinXScale = (minValue, total) => {
    if ((minValue && minValue >= 0) || minValue === "-") {
      return xScale(0.0)
    }
    return xScale(total * Math.abs(minValue / 100))
  }

  const calculateMaxXScale = (maxValue, total) => {
    if ((maxValue && maxValue <= 0) || maxValue === "-") {
      return xScale(0.0)
    }
    return xScale(total * Math.abs(maxValue / 100))
  }

  const getMinValues = (value, total) => {
    let displayValue = ""
    if (value < 0) {
      const absValue = Math.abs(value)
      displayValue = `-${formatToFloat(absValue, 2, "%")}`
      if (distributionOption === "weight" && !baseAmount) {
        return displayValue
      }
      if (total > 0) {
        const amountValue = (total * absValue) / 100
        displayValue = `-$${amountValue < 1000000000000000
          ? formatAmounts(amountValue)
          : formatAmount(+amountValue)
          }`
      }
    } else if (value === "-") {
      displayValue = "-"
    } else if (distributionOption === "weight" && !baseAmount) {
      displayValue = "0%"
    } else {
      displayValue = "$0"
    }
    return displayValue
  }

  const getMaxValues = (value, total) => {
    let displayValue = ""
    if (value > 0) {
      displayValue = `${formatToFloat(value, 2, "%")}`
      if (distributionOption === "weight" && !baseAmount) {
        return displayValue
      }

      if (total > 0) {
        const amountValue = (total * value) / 100
        displayValue = `$${amountValue < 1000000000000000
          ? formatAmounts(amountValue)
          : formatAmount(+amountValue)
          }`
      }
    } else if (value === "-") {
      displayValue = "-"
    } else if (distributionOption === "weight" && !baseAmount) {
      displayValue = "0%"
    } else {
      displayValue = "$0"
    }
    return displayValue
  }

  return (
    <div
      className={`flex flex-col ${data[0].portfolios[1] ? "gap-4" : "gap-2"}`}
    >
      {data.map((d) => (
        <div key={d.label} className="space-y-1">
          <p className="w-full text-xs font-bold text-slate-500 dark:text-slate-400 uppercase">
            {d.label}
          </p>
          <div className="w-full">
            <svg width="100%" height="50">
              <g transform={`translate(0, ${10})`}>
                {/* text left */}
                <g transform={`translate(${5},${0})`}>
                  <g>
                    <text
                      className={
                        colorScheme === "dark"
                          ? styles.barLabelDark
                          : styles.barLabel
                      }
                      x={0}
                      y={yScale(0) + yScale.bandwidth() / 2}
                    >
                      {getMinValues(
                        d.portfolios[0].min,
                        d.portfolios[0].total,
                        "-",
                      )}
                    </text>
                  </g>
                </g>
                {d.portfolios[1] && (
                  <g transform={`translate(${5},${15})`}>
                    <g>
                      <text
                        className={
                          colorScheme === "dark"
                            ? styles.barLabelDark
                            : styles.barLabel
                        }
                        x={0}
                        y={yScale(1) + yScale.bandwidth() / 2}
                      >
                        {getMinValues(
                          d.portfolios[1].min,
                          d.portfolios[1].total,
                          "-",
                        )}
                      </text>
                    </g>
                  </g>
                )}
                {/* end text left */}
                {/* graph left */}
                <g transform={`translate(${chartConfig.labelWidth},${-5})`}>
                  <rect
                    className={
                      colorScheme === "dark"
                        ? styles.rectBackgroundDark
                        : styles.rectBackground
                    }
                    x={0}
                    y={0}
                    width={chartConfig.barGroupWidth}
                    height={chartConfig.barGroupHeight}
                  />
                  <g>
                    <rect
                      x={
                        chartConfig.barGroupWidth -
                        calculateMinXScale(
                          d.portfolios[0].min,
                          d.portfolios[0].total,
                        )
                      }
                      y={yScale(0)}
                      width={calculateMinXScale(
                        d.portfolios[0].min,
                        d.portfolios[0].total,
                      )}
                      height={8}
                      fill={minColorPalette[0]}
                    />
                  </g>
                </g>
                {d?.portfolios[1] && (
                  <g transform={`translate(${chartConfig.labelWidth},${15})`}>
                    <rect
                      className={
                        colorScheme === "dark"
                          ? styles.rectBackgroundDark
                          : styles.rectBackground
                      }
                      x={0}
                      y={0}
                      width={chartConfig.barGroupWidth}
                      height={chartConfig.barGroupHeight}
                    />
                    <g>
                      <rect
                        x={
                          chartConfig.barGroupWidth -
                          calculateMinXScale(
                            d.portfolios[1].min,
                            d.portfolios[1].total,
                          )
                        }
                        y={yScale(0)}
                        width={calculateMinXScale(
                          d.portfolios[1].min,
                          d.portfolios[1].total,
                        )}
                        height="8"
                        fill={minColorPalette[1]}
                      />
                    </g>
                  </g>
                )}
                {/* end graph left */}
                {/* graph right */}
                <g
                  transform={`translate(${chartConfig.barGroupWidth + chartConfig.labelWidth + 5
                    },${-5})`}
                >
                  <rect
                    className={
                      colorScheme === "dark"
                        ? styles.rectBackgroundDark
                        : styles.rectBackground
                    }
                    x={0}
                    y={0}
                    width={chartConfig.barGroupWidth}
                    height={chartConfig.barGroupHeight}
                  />
                  <g>
                    <rect
                      x={0}
                      y={yScale(0)}
                      width={calculateMaxXScale(
                        d.portfolios[0].max,
                        d.portfolios[0].total,
                      )}
                      height={8}
                      fill={colors[0]}
                    />
                  </g>
                </g>
                {d.portfolios[1] && (
                  <g
                    transform={`translate(${chartConfig.barGroupWidth + chartConfig.labelWidth + 5
                      },${15})`}
                  >
                    <rect
                      className={
                        colorScheme === "dark"
                          ? styles.rectBackgroundDark
                          : styles.rectBackground
                      }
                      x={0}
                      y={0}
                      width={chartConfig.barGroupWidth}
                      height={chartConfig.barGroupHeight}
                    />
                    <g>
                      <rect
                        x={0}
                        y={0}
                        width={calculateMaxXScale(
                          d.portfolios[1].max,
                          d.portfolios[1].total,
                        )}
                        height={8}
                        fill={colors[1]}
                      />
                    </g>
                  </g>
                )}
                {/* end graph right */}
                {/* text right */}
                <g
                  transform={`translate(${2 * chartConfig.barGroupWidth + chartConfig.labelWidth + 8
                    },${0})`}
                >
                  <g>
                    <text
                      className={
                        colorScheme === "dark"
                          ? styles.barLabelDark
                          : styles.barLabel
                      }
                      x={10}
                      y={yScale(0) + yScale.bandwidth() / 2}
                    >
                      {getMaxValues(d.portfolios[0].max, d.portfolios[0].total)}
                    </text>
                  </g>
                </g>
                {d.portfolios[1] && (
                  <g
                    transform={`translate(${2 * chartConfig.barGroupWidth + chartConfig.labelWidth + 8
                      },${15})`}
                  >
                    <g>
                      <text
                        className={
                          colorScheme === "dark"
                            ? styles.barLabelDark
                            : styles.barLabel
                        }
                        x={10}
                        y={yScale(1) + yScale.bandwidth() / 2}
                      >
                        {getMaxValues(
                          d.portfolios[1].max,
                          d.portfolios[1].total,
                        )}
                      </text>
                    </g>
                  </g>
                )}
                {/* end text right */}
              </g>
            </svg>
          </div>
        </div>
      ))}
    </div>
  )
}

MinMaxBarChartSVG.propTypes = {
  data: minMaxChartDataPropTypes.isRequired,
  yScaleDomain: PropTypes.arrayOf(PropTypes.number).isRequired,
  dataXDomain: PropTypes.arrayOf(PropTypes.number).isRequired,
  chartConfig: minMaxChartConfig.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  distributionOption: PropTypes.string.isRequired,
  baseAmount: PropTypes.number,
}
MinMaxBarChartSVG.defaultProps = {
  colors: ["#FBBF24", "#14B8A6"],
  baseAmount: null,
}

export default MinMaxBarChartSVG
