import { useState, useEffect, useContext } from "react"
import { useSelector } from 'react-redux';
import PropTypes from "prop-types"
import {
  DocumentChartBarIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline"

// HOOKS
import useFundFactsheets from "../../../hooks/useFundFactsheets"
// UTILS
import ShareClassFactory from "../../../utils/ShareClassFactory"
import { FirebaseContext } from "../../../firebase"
// SENTRY
import * as Sentry from "@sentry/browser";


function FundDocumentsNav({ fund, modalInfo }) {
  const firebase = useContext(FirebaseContext)
  // STATE
  const [dataForDocs, setDataForDocs] = useState(null)
  // HOOKS
  const [openFactsheet, openProspectus] = useFundFactsheets()
  // STORE
  const currency = useSelector((state)=>state.proposalType.currency);

  useEffect(() => {
    const fetchData = async (fundId, shareClassName, isAPV, fundType) => {
      try {
        // console.log("instancio")
        const shareClassInstance = await ShareClassFactory.getShareClass(
          fundId,
          shareClassName,
          isAPV,
          fundType,
          firebase,
          currency
        )
        setDataForDocs(shareClassInstance.fundGeneralInfo)
      } catch (e) {
        Sentry.captureException(e);
        console.log("Error in fetch", e)
      }
    }
    fetchData(fund.run, fund.serie, fund.is_apv, fund.fund_type)
  }, [fund])

  return (
    dataForDocs && (
      <div className="flex items-center gap-0">
        <button
          type="button"
          className="relative group w-5 h-5 grow-0 hover:text-blue-500 dark:hover:text-blue-400"
          onClick={() =>
            openFactsheet(fund.run, fund.serie, fund.fund_provider_id, () => {
              modalInfo.setFactSheetModalMessage(
                `No existe ficha reportada en la CMF para el Fondo ${fund.run} y serie ${fund.serie}.`,
              )
              modalInfo.setFactSheetModalOpen(true)
            })
          }
        >
          <DocumentChartBarIcon className="w-5 h-5" />
          <span
            className="absolute left-1/2 transform -translate-x-1/2 bottom-full
            mb-2 px-2 py-1 text-xs text-slate-900 dark:text-slate-50 bg-slate-300 dark:bg-slate-700 rounded hidden
            group-hover:block transition-opacity duration-500 w-28"
          >
            Ver folleto informativo serie {fund.serie}
          </span>
        </button>
        <button
          type="button"
          className="relative group w-5 h-5 grow-0 hover:text-blue-500 dark:hover:text-blue-400"
          onClick={() => {
            openProspectus(
              fund.run,
              fund.serie,
              dataForDocs.prospectusCode,
              () => {
                modalInfo.setFactSheetModalMessage(
                  `No existe reglamento interno reportado en la CMF para el Fondo ${fund.run} y serie ${fund.serie}.`,
                )
                modalInfo.setFactSheetModalOpen(true)
              },
            )
          }}
        >
          <DocumentMagnifyingGlassIcon className="w-5 h-5" />
          <span
            className="absolute left-1/2 transform -translate-x-1/2 bottom-full
            mb-2 px-2 py-1 text-xs text-slate-900 dark:text-slate-50 bg-slate-300 dark:bg-slate-700 rounded hidden
            group-hover:block transition-opacity duration-500 w-28"
          >
            Reglamento interno del fondo
          </span>
        </button>
      </div>
    )
  )
}

FundDocumentsNav.propTypes = {
  fund: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  modalInfo: PropTypes.shape({
    setFactSheetModalMessage: PropTypes.func,
    setFactSheetModalOpen: PropTypes.func,
  }).isRequired,
}

export default FundDocumentsNav
