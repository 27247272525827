import { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { useSelector } from "react-redux"
import {
  panelDataPropTypes,
  FundDataShape,
} from "../../../propTypes/AppPropTypes"

// UI
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/shadcn/ui/tooltip";

// COMPONENTS
import LineChart from "../../charts/LineChart"
import FundDetailsPerformanceBySerie from "./FundDetailsPerformanceBySerie"
import FundDetailsPerformanceByDAP from "./FundDetailsPerformanceByDAP"

// HOOKS
import usePrepareDataForCharts from "../../../hooks/usePrepareDataForCharts"

// UTILS
import {
  calculateRelativeDateFrom,
  parseAPIDate,
  getDifferenceInMonths,
} from "../../../utils/DateCalculations"
import { CURRENCIES_MAP } from "@/components/ui/utilities/dataMaps";

// import { graphShift } from "../../../utils/ReturnsCalculations"

import { displayString } from "../utilities/functions"

function FundDetailsPerformance({ data, rowData }) {
  // HOOKS
  const { filterUnqualifiedSeries } = usePrepareDataForCharts()

  // STORE
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  const currency = useSelector((state) => state.proposalType.currency)

  const timePeriods = useMemo(
    () => ["1 Mes", "3 Meses", "6 Meses", "YTD", "1 Año", "2 Años", "3 Años"],
    [],
  )

  // STATE
  const [timePeriod, setTimePeriod] = useState(-1)
  const [performanceData, setPerformanceData] = useState(data.performance)
  const initialPeriodList = [
    { key: "3 Años", value: 36 },
    { key: "2 Años", value: 24 },
    { key: "1 Año", value: 12 },
    { key: "YTD", value: -2 },
    { key: "6 meses", value: 6 },
    { key: "3 meses", value: 3 },
    { key: "1 mes", value: 1 },
  ]
  const [periodList, setPeriodList] = useState(initialPeriodList)
  const [nullDates, setNullDates] = useState([])
  const [firstPortfoliosPerformanceDate] = useState(
    parseAPIDate(data.performance[-1][0].data[0].date),
  )
  const [lastPortfoliosPerformanceDate] = useState(
    parseAPIDate(
      data.performance[-1][0].data[data.performance[-1][0].data.length - 1]
        .date,
    ),
  )
  const ageInMonths = getDifferenceInMonths(
    lastPortfoliosPerformanceDate,
    firstPortfoliosPerformanceDate,
  )


  let currencySuffix = "Usd"
  if (currency === "CLP") {
    currencySuffix = "Clp"
  }
  const tableReturns = useState(filterUnqualifiedSeries(data.returnsTable.data))
  const tableFill = (tData) => {
    const table = {}
    tData.forEach((tableRow) => {
      table[tableRow.serie] = {
        return1M: tableRow[`return1M${currencySuffix}`],
        return3M: tableRow[`return3M${currencySuffix}`],
        return6M: tableRow[`return6M${currencySuffix}`],
        return1Y: tableRow[`return1Y${currencySuffix}`],
        return2Y: tableRow[`return2Y${currencySuffix}`],
        return3Y: tableRow[`return3Y${currencySuffix}`],
        returnYTD: tableRow[`returnYTD${currencySuffix}`],
      }
    })
    return table
  }
  const series = useState(Object.keys(tableFill(tableReturns[0])))
  const displaySeries = series[0]

  const addDataToTable = (table, shareClassName, shareClassData) => {
    table["1 mes"].push({ [shareClassName]: shareClassData.return1M })
    table["3 meses"].push({ [shareClassName]: shareClassData.return3M })
    table["6 meses"].push({ [shareClassName]: shareClassData.return6M })
    table["12 meses"].push({ [shareClassName]: shareClassData.return1Y })
    table["2 años"].push({ [shareClassName]: shareClassData.return2Y })
    table["3 años"].push({ [shareClassName]: shareClassData.return3Y })
    table.YTD.push({ [shareClassName]: shareClassData.returnYTD })
    return table
  }
  const presenter = (tData) => {
    const currentTData = { ...tData }
    let table = {
      "1 mes": [],
      "3 meses": [],
      "6 meses": [],
      "12 meses": [],
      "2 años": [],
      "3 años": [],
      YTD: [],
    }
    // add data for current shareclass
    const currentShareClassName = data.overview.shareClass.shareClassName
    const tableRowCS = currentTData[currentShareClassName]
    table = addDataToTable(table, currentShareClassName, tableRowCS)

    // move current shareclass to begin
    displaySeries.splice(displaySeries.indexOf(currentShareClassName), 1)
    displaySeries.unshift(currentShareClassName)

    delete currentTData[currentShareClassName]
    Object.keys(currentTData).forEach((s) => {
      const tableRow = currentTData[s]
      table = addDataToTable(table, s, tableRow)
    })
    return table
  }

  const [tableData, setTableData] = useState(presenter(tableFill(tableReturns[0])))

  const performanceToNull = (p, nullDates) => {
    const newP = p.map((row) => {
      if (nullDates.includes(row.date)) {
        return { ...row, value: null }
      }
      return row
    })
    return newP
  }

  useEffect(() => {
    const filteredPeriodList = []
    let firstPerfDate = null
    periodList.forEach((pl) => {
      if (pl.key !== "YTD") {
        firstPerfDate = calculateRelativeDateFrom(
          lastPortfoliosPerformanceDate,
          pl.value,
        )
        if (firstPerfDate >= firstPortfoliosPerformanceDate) {
          filteredPeriodList.push(pl)
        }
      } else {
        filteredPeriodList.push(pl)
      }
    })
    setPeriodList(filteredPeriodList)
    const sc = data.overview.shareClass.instance
    const per = sc.calcAccRet(
      performanceData[-1][0].name,
      firstPortfoliosPerformanceDate,
      lastPortfoliosPerformanceDate,
      filteredPeriodList[0].value,
    )
    const performanceFPL = per[0]
    setNullDates([sc.returnsLagoon])
    setPerformanceData({
      ...performanceData,
      ...{
        [filteredPeriodList[0].value]: [
          {
            name: performanceFPL.name,
            data: performanceToNull(performanceFPL.data, sc.returnsLagoon),
          },
        ],
      },
    })
    setTimePeriod(filteredPeriodList[0].value)
    setTableData(presenter(tableFill(tableReturns[0])))
  }, [data, timePeriods, currency])

  if (Object.keys(data.performance).length === 0) {
    return (
      <div className="w-full">
        <p>No data</p>
      </div>
    )
  }

  const getColDistribution = () => {
    let spanList = []
    switch (displaySeries.length) {
      case 1:
        spanList = ["col-span-10", "col-span-2"]
        break
      case 2:
      case 3:
        spanList = ["col-span-9", "col-span-3"]
        break
      case 4:
      case 5:
        spanList = ["col-span-8", "col-span-4"]
        break
      case 6:
        spanList = ["col-span-7", "col-span-5"]
        break
      default:
        spanList = ["col-span-6", "col-span-6"]
    }
    return spanList
  }

  const getPerformanceTable = () => {
    switch (rowData.fund_type) {
      case "IN":
        return (
          <FundDetailsPerformanceByDAP
            getColDistribution={getColDistribution()[1]}
            dataShareClass={data.overview.shareClass}
            displaySeries={displaySeries}
            tableData={tableData}
          />
        )
      default:
        return (
          <FundDetailsPerformanceBySerie
            getColDistribution={getColDistribution()[1]}
            dataShareClass={data.overview.shareClass}
            displaySeries={displaySeries}
            tableData={tableData}
          />
        )
    }
  }

  const getInformationByType = () => {
    switch (rowData.fund_type) {
      case "IN":
        return {
          description: (
            <p>
              Para la rentabilidad histórica de los depósitos a plazo se usa
              como base la tasa de los instrumentos asociados a un indicador
              provisto por LVA Índices denominado LKXI2P1 y/o LKXI6P1, que
              representa la rentabilidad promedio de un depósito en pesos de
              riesgo N1 para los plazos de 30, 90, 180 y 360 días.
            </p>
          ),
          lineChartTitle: "Rentabilidad histórica",
        }
      default:
        return {
          description: (
            <p>
              {`El cálculo de rentabilidad considera todos los eventos de capital
              (dividendos, repartos, etc) y está calculado en ${CURRENCIES_MAP[currency]} (${currency})`}
            </p>
          ),
          lineChartTitle: `Rentabilidad histórica - Serie ${displayString(
            data.overview.shareClass.shareClassName,
          )}`,
        }
    }
  }

  return (
    periodList && (
      <section className="h-full">
        <div className="flex ">
          <h1 className="overview-section-title">Rentabilidad {CURRENCIES_MAP[currency]}</h1>
          <div className="group relative mx-1 leading-0 translate-y-0.5 z-50">
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="text-primary">
                  <InformationCircleIcon className="w-5 h-5 shrink-0" />
                </TooltipTrigger>
                <TooltipContent side="bottom" className="bg-muted text-muted-foreground max-w-48">
                  {getInformationByType().description}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div className="overview-section-grid ">
          {Object.keys(data.performance).length > 0 && (
            <div className={getColDistribution()[0]}>
              <header className="flex items-center justify-between mb-5">
                <h2 className="overview-section-subtitle !mb-0">
                  {getInformationByType().lineChartTitle}
                </h2>
                <select
                  name="TimePeriod"
                  value={timePeriod}
                  onChange={(event) => {
                    // arreglar setPerformance para no volver a calcular
                    // caso volver a historico
                    if (!performanceData[event.target.value]) {
                      const per = data.overview.shareClass.instance.calcAccRet(
                        performanceData[-1][0].name,
                        firstPortfoliosPerformanceDate,
                        lastPortfoliosPerformanceDate,
                        +event.target.value,
                      )[0]
                      setPerformanceData({
                        ...performanceData,
                        ...{
                          [event.target.value]: [
                            {
                              name: per.name,
                              data: performanceToNull(
                                per.data,
                                data.overview.shareClass.instance.returnsLagoon,
                              ),
                            },
                          ],
                        },
                      })
                    }
                    setTimePeriod(+event.target.value)
                  }}
                  className="bg-slate-100 dark:bg-slate-800 w-24 px-2 py-1 text-xs rounded-md text-slate-900 dark:text-slate-50 !outline-none border border-slate-300 dark:border-slate-700"
                >
                  {periodList.map((tp) => {
                    const apvExcludePeriods = [1, 3, -2, 6]
                    let option = null
                    if (isAPV) {
                      const hasLessThanOneYear =
                        ageInMonths < 12 && ageInMonths > 6
                      if (hasLessThanOneYear && tp.value === 6) {
                        option = (
                          <option value={6} key="6 meses">
                            6 meses
                          </option>
                        )
                      } else if (!apvExcludePeriods.includes(tp.value)) {
                        option = (
                          <option value={tp.value} key={tp.key}>
                            {tp.key}
                          </option>
                        )
                      } else {
                        return null
                      }
                    } else {
                      option = (
                        <option value={tp.value} key={tp.key}>
                          {tp.key}
                        </option>
                      )
                    }
                    return option
                  })}
                </select>
              </header>
              <LineChart
                performanceData={performanceData[timePeriod]}
                colors={["#60A5FA"]}
                isSeriesChart
                emptyDates={nullDates}
              />
            </div>
          )}

          {getPerformanceTable()}
        </div>
      </section>
    )
  )
}
FundDetailsPerformance.propTypes = {
  data: panelDataPropTypes.isRequired,
  rowData: PropTypes.shape(FundDataShape).isRequired,
}
export default FundDetailsPerformance
