import {
  portfolioAcc,
  calculateReturnsStatistics,
  getPeriodReturn,
  getPeriodAnnualizedReturn,
} from "./ReturnsCalculations"
import {
  calculateRelativeDateFrom,
  calculateYTDDateFrom,
  getDifferenceInMonths,
} from "./DateCalculations"
import { unifyExposure } from "./ExposureCalculations"
import { INITIAL_PERIODLIST, INITIAL_PERIODLIST_APV } from "./constants"
import { toJSON } from "danfojs"
// SENTRY
import * as Sentry from "@sentry/browser";


class PortfolioGroup {
  constructor(
    isAPV,
    portfolios = [],
  ) {
    // isAPV boolean
    this.isAPV = isAPV
    // dictonary of portfolios
    this.portfolios = portfolios
    // list of periods
    this.initialPeriodList = isAPV ? INITIAL_PERIODLIST_APV : INITIAL_PERIODLIST
    this.firstDate
    this.lastDate
    this.periodList
    this.returnTable
  }

  getFirstDate() {
    return this.firstDate
  }

  getLastDate() {
    return this.lastDate
  }

  getFilteredPeriodList() {
    return this.filteredPeriodList
  }

  getReturnTable() {
    return this.returnTable
  }

  addPortfolio(portfolio) {
    this.portfolios.push(portfolio)
  }

  removePortfolio(portfolio) {
    const index = this.portfolios.indexOf(portfolio)
    this.portfolios.splice(index, 1)
  }

  getReturnFromTo(from, to) {
    const returns = []
    const promiseArray = this.portfolios.map(async (portfolio) => {
      const returnData = await portfolio.getAccReturns(to, from)

      // substract distance to each value
      const colAddDiff = returnData.value.add(-1).mul(100)

      // replace value column with calculated value
      const formattedDF = returnData.copy()
      formattedDF.addColumn("value", colAddDiff, { inplace: true })
      const formattedData = toJSON(formattedDF, {
        format: "column",
      })
      returns.push({ name: portfolio.name, data: formattedData })
    })
    Promise.all(promiseArray)
    return returns
  }

  async getAccumulatedReturns(lastDateSelected = null) {
    try {
      // Paso 1: Obtener estadísticas de retornos acumulados
      const summaryReturnsStatistics = await calculateReturnsStatistics(this.portfolios);
      // eslint-disable-next-line no-unused-vars
      const { portfoliosAccReturns, minReturnsLastDay, maxReturnsFirstDay } = summaryReturnsStatistics;

      // Asignar fechas iniciales y finales
      this.firstDate = maxReturnsFirstDay;
      this.lastDate = minReturnsLastDay;

      // Fechas del primer y último día
      const fpDate = maxReturnsFirstDay;
      const lpDate = lastDateSelected ? lastDateSelected : minReturnsLastDay;
      //   const lpDate = minReturnsLastDay;

      const ageInMonths = getDifferenceInMonths(lpDate, fpDate)
      const hasLessThanOneYear =
        ageInMonths.current < 12 && ageInMonths.current >= 6

      // Paso 2: Filtrar la lista de periodos basada en la fecha máxima y mínima
      const mapPeriodList = []
      this.initialPeriodList.map((pl) => {
        const periodFirstPerformanceDate = calculateRelativeDateFrom(lpDate, pl.value);
        if (pl.key === "YTD") {
          mapPeriodList.push({ ...pl, date: [calculateYTDDateFrom(lpDate, pl.value), lpDate] }); // Mantener "YTD" siempre
        }
        else if (periodFirstPerformanceDate >= fpDate) {
          mapPeriodList.push({ ...pl, date: [periodFirstPerformanceDate, lpDate] }); // Mantener el periodo si la fecha es válida
        }

      });
      this.filteredPeriodList = mapPeriodList;

      // Paso 3: Calcular los retornos acumulados para cada periodo en paralelo usando Promise.all
      const shiftedReturns = await Promise.all(
        this.filteredPeriodList.map(async (pl) => {
          const pAcc = await portfolioAcc(this.portfolios, +pl.value, fpDate, lpDate, 0);
          return { [pl.value]: pAcc }; // Devolvemos pl.value junto con pAcc
        })
      );

      // Revisar qué se recibe en `shiftedReturns`
      const shiftedReturnsObj = shiftedReturns.reduce((acc, obj) => {
        return { ...acc, ...obj }; // Combina el acumulador con el objeto actual
      }, {});

      const retTPD = {}

      this.filteredPeriodList.forEach((pl) => {
        const periodTime = +pl.value
        shiftedReturnsObj[periodTime].forEach((port) => {
          const periodName = pl.key
          if (this.isAPV) {
            const periodValue = getPeriodAnnualizedReturn(
              periodTime,
              port.data,
              port.data[port.data.length - 1].date,
            )
            const excludedAPVPeriods = [-2, 6, 3, 1]
            if (!excludedAPVPeriods.includes(periodTime)) {
              if (hasLessThanOneYear.current && periodTime === 6) {
                retTPD[port.name] = {
                  ...retTPD[port.name],
                  "6 meses": periodValue,
                }
              } else {
                retTPD[port.name] = {
                  ...retTPD[port.name],
                  [periodName]: periodValue,
                }
              }
            }

          } else {
            const periodValue = getPeriodReturn(
              port.data,
              port.data[port.data.length - 1].date,
            )
            retTPD[port.name] = {
              ...retTPD[port.name],
              [periodName]: periodValue,
            }
          }
        })
      })
      this.returnTable = retTPD

      return shiftedReturnsObj;
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error en getAccumulatedReturns:", error);
    }
  }

  async getMinMaxProfitAndLosses(lastDateSelected = null) {
    const summaryReturnsStatistics = await calculateReturnsStatistics(this.portfolios, lastDateSelected);
    const maxMinProfitAndLosses =
      summaryReturnsStatistics.portfoliosMinMaxProfitAndLosses
    return maxMinProfitAndLosses
  }

  async getTac() {
    const tacData = []
    this.portfolios.forEach(async (portfolio) => {
      const tac = portfolio.getTac()
      tacData.push(tac)
    })
    await Promise.all(tacData)
    return tacData
  }

  async getIssuers() {
    const issuersData = []
    this.portfolios.forEach(async (portfolio) => {
      const issuers = portfolio.getIssuers()
      issuersData.push(issuers)
    })
    await Promise.all(issuersData)
    return issuersData
  }

  async getExposures() {
    const exposures = []
    this.portfolios.forEach(async (portfolio) => {
      const exposure = portfolio.getExposures()
      exposures.push(exposure)
    })
    await Promise.all(exposures)
    const exposureData = unifyExposure(exposures[0], exposures[1])
    return exposureData
  }

  async getExposureSummary() {
    const exposures = []
    let exposureSummary = {}

    if (this.portfolios.length === 1) {
      const exposure = this.portfolios[0].getExposureExtendedSummary()
      exposures.push(exposure)
      await Promise.all(exposures)
      exposureSummary = exposures[0]
      exposureSummary["cantidad"] = 1

    }

    else if (this.portfolios.length === 2) {
      this.portfolios.forEach(async (portfolio) => {
        const exposure = portfolio.getExposureSummary()
        exposures.push(exposure)
      })
      await Promise.all(exposures)

      const firstDate = exposures[0]["fecha"]
      const secondDate = exposures[1]["fecha"]

      const maxClosingDate = firstDate > secondDate ? firstDate : secondDate

      exposureSummary["fecha"] = maxClosingDate
      exposureSummary[this.portfolios[0].id] = exposures[0][this.portfolios[0].id]
      exposureSummary[this.portfolios[1].id] = exposures[1][this.portfolios[1].id]
      exposureSummary["cantidad"] = 2

    }

    return exposureSummary
  }

}
export default PortfolioGroup
